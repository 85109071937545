import NEAREST_ZIP from '../gql/NearestZip';
import {NearestZip, NearestZipVariables} from '../gql/types/NearestZip';
import {apolloClient} from './apollo';
import sessionStorage from '../lib/session-storage';

const USE_BROWSER_GEO = false;

export function geoLookup() {
    const data = sessionStorage.getItem('geo');

    if (data)
        return Promise.resolve(JSON.parse(data));

    return new Promise((resolve, reject) => {
        const lookup = (latitude:number, longitude:number) => {
            apolloClient.query<NearestZip, NearestZipVariables>({
                query: NEAREST_ZIP,
                variables: {latitude, longitude}
            }).then(({data}) => {
                if(!data)
                    throw Error('No data!');

                return data.nearestZip
            }).then((location:any) => {
                sessionStorage.setItem('geo', JSON.stringify(location));
                resolve(location);
            }).catch(reject);
        };

        const geoIpDb = () => fetch('https://geolocation-db.com/json/')
            .then(response => response.json())
            .then(({latitude, longitude}):any => {
                //console.log('looking up location using coordinates from geoip-db.com');
                lookup(latitude, longitude);
            })
            .catch(reject);

        if (USE_BROWSER_GEO && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(({coords:{latitude, longitude}}) => {
                //console.log(`looking up location using coordinates from navigator.geolocation`);
                lookup(latitude, longitude);
            }, () => geoIpDb())
        }
        else {
            geoIpDb().then();
        }
    });
}