import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  path, polygon {
    fill: currentColor;
  }
`;

const Logo = () => (
<Svg
    xmlns="http://www.w3.org/2000/svg"
    width="2.099654in"
    height="0.83077908in"
    version="1.0"
    viewBox="164 147.36334 786.00006 310.83571"
>
 <path d="M 171,147 H 911 V 458 H 164 V 147 Z m 727,13 H 177 v 285 h 721 z"/>
 <path d="m 335,266 c 0,19 -10,30 -31,30 h -36 v -59 h 36 c 21,0 31,12 31,29 z M 210,189 v 237 h 58 v -89 h 14 l 49,89 h 65 l -55,-94 c 37,-10 53,-38 53,-68 0,-42 -29,-75 -87,-75 z m 351,165 c 0,17 -11,26 -30,26 h -43 v -53 h 42 c 19,0 31,10 31,27 z m -5,-94 c 0,17 -11,25 -30,25 h -38 v -50 h 38 c 19,0 30,9 30,25 z m 64,102 c 0,-27 -18,-52 -46,-57 23,-7 40,-26 40,-54 0,-38 -28,-62 -77,-62 H 430 v 237 h 111 c 50,0 79,-25 79,-64 z M 808,189 V 336 L 711,189 h -58 v 237 h 58 V 280 l 97,146 h 58 V 189 Z"/>
 <polygon points="924,164 927,164 927,150 933,150 933,148 919,148 919,150 924,150 "/>
 <polygon points="934,148 933,164 936,164 937,154 941,164 943,164 947,154 947,164 950,164 949,148 946,148 942,160 937,148 "/>
</Svg>
);

export default Logo;