import queryString from "query-string";

import sessionStorage from './session-storage';
import {VERIFICATION_METHOD_KEY, VerificationMethod} from "../pages/Dashboard/Consumer/Setup/Setup";

const VerificationParamMap:{[key:string]: number} = {
    '0': 0,
    '1': 1,
    '2': 2,
    'e': 0,
    'p': 1,
    'b': 2
}

export const ONBOARDED_BY_KEY = 'onboardedBy';
export const ONBOARDING_KEY = 'onboarding';

export default function checkLanderQueryStringParams() {
    const qsp = queryString.parse(document.location.search);

    if (!qsp)
        return;

    if (qsp.v) {
        const k = ((qsp.v instanceof Array ? qsp.v[0] : qsp.v) || '').toLowerCase();
        const v: VerificationMethod = VerificationParamMap[k];
        if (VerificationMethod[v])
            sessionStorage.setItem(VERIFICATION_METHOD_KEY, v.toString());
    }

    if (qsp['onboarded-by']) {
        sessionStorage.setItem(ONBOARDED_BY_KEY, String(qsp['onboarded-by']));
        sessionStorage.setItem(ONBOARDING_KEY, 'true');
    }
}