import {useMutation} from "@apollo/client";
import {CreateInvitation as CreateInvitationResult, CreateInvitationVariables} from "../gql/types/CreateInvitation";
import CREATE_INVITATION from "../gql/CreateInvitation";
import {FormEvent, useState} from "react";
import useFields from "./use-fields";
import {RegisterFields, useLoginRedirect, UseRegister} from "../config";

export function useInviteAgent(defaultValues?:RegisterFields):UseRegister {
    const {fields, updateField, updateFields} = useFields(['firstName', 'lastName', 'email', 'phone'], defaultValues);
    const [error, setError] = useState('');
    const [createInvitation] = useMutation<CreateInvitationResult, CreateInvitationVariables>(CREATE_INVITATION);
    const loginRedirect = useLoginRedirect();

    const register = (event?:FormEvent) => {
        event && event.preventDefault();

        return createInvitation({
            variables: {
                input: {
                    invitation: Object.assign({
                            info: {
                                type:'agent'
                            }
                        },
                        fields)
                }
            }
        })
            .then(loginRedirect)
            .catch(e => setError(e));
    };

    return {
        error,
        fields,
        updateField,
        updateFields,
        register
    };
}
