import React from 'react';
import Step from "./Step";
import {PageProps} from "../../../../lib/create-track";
import Content from './Content';
import {useMutation, useQuery} from "@apollo/client";
import {FindProviders, FindProvidersVariables} from "../../../../gql/types/FindProviders";
import FIND_PROVIDERS from '../../../../gql/FindProviders';
import {useConsumerViewer} from "../../../../lib/use-viewer";
import {ProviderType} from "../../../../types/graphql-global-types";
import Agent from "../../../../components/Provider/Agent";
import {Button} from "../../../../components/form";
import TopMessage from "./TopMessage";
import Name from "../../../../components/Name";
import {UpdateInquiry as UpdateInquiryResult, UpdateInquiryVariables} from "../../../../gql/types/UpdateInquiry";
import UPDATE_INQUIRY from "../../../../gql/UpdateInquiry";
import {useLocation} from "react-router-dom";
import Spinner from "../../../../components/Spinner";

export interface StateProps {
    agentId?: string
}

const SelectAnAgent = ({page:{next}}:PageProps) => {
    const {state} = useLocation<StateProps>();
    const {viewer} = useConsumerViewer();

    const inquiry = viewer &&
        viewer.consumer &&
        viewer.consumer.inquiries &&
        viewer.consumer.inquiries.nodes &&
        viewer.consumer.inquiries.nodes.find(inquiry => !!(inquiry && !inquiry.detailsConfirmed));

    const details = inquiry && inquiry.details;

    const {data} = useQuery<FindProviders, FindProvidersVariables>(FIND_PROVIDERS, {
        variables: {
            type: ProviderType.REAL_ESTATE_AGENT,
            inquiryId: inquiry && inquiry.id
        }
    });

    const agentIds = ((data && data.findProviders && data.findProviders.nodes)||[]).map(p => p && p.id);

    const [updateInquiryMutation] =  useMutation<UpdateInquiryResult, UpdateInquiryVariables>(UPDATE_INQUIRY);

    const selectAgent = (id:string) => {
        if (!details) {
            console.error('no inquiry details!', inquiry);
            return;
        }

        const selectedAgents = ((details && details.selectedAgents) || []).slice();
        if (selectedAgents.length === 0 || selectedAgents[selectedAgents.length - 1] !== id)
            selectedAgents.push(id);

        updateInquiryMutation({
            variables: {
                input: {
                    nodeId: (inquiry && inquiry.nodeId) || '',
                    patch: {
                        details: Object.assign({},
                            details,
                            {
                                selectedAgents,
                                selectedAgent: id
                            }
                        )
                    }
                }
            }
        })
            .then(() => next({valid:true}));
    };

    if (state && state.agentId) {
        selectAgent(state.agentId);

        return <Spinner/>;
    }

    return (
        <Step heading="Select an Agent">
            <Content>
                <TopMessage>
                    <p>
                        <Name>{viewer && viewer.firstName}</Name>,
                    </p>
                    <p>
                        Thank you for confirming your Purchase Profile information.
                    </p>
                    <p>
                        Let's connect you with the agents that match your Purchase Profile.
                    </p>
                    <p>
                        Click the <strong>view full profile</strong> button to see each agent's sales volume and recent transactions.
                    </p>
                    <p>
                        Click the <strong>select</strong> button when you're ready to proceed.
                    </p>
                    {details.currentlyWorkingWithAnAgent && (
                        <p>
                            NOTE:  RBN respects existing contractual obligations with agents and their clients. Customers with existing agent agreements are not eligible to earn RBN Rewards.
                        </p>
                    )}
                </TopMessage>
                {agentIds.map(id => (
                    <Agent key={id} id={id} hidePhone={true} button={<Button variation="green" onClick={() => selectAgent(id)}>Select</Button>}/>
                ))}
            </Content>
        </Step>
    )
};

export default SelectAnAgent;