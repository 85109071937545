import React from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import sessionStorage from '../../../../lib/session-storage';
import {getVerificationMethod, INVITE_AN_AGENT_KEY, VerificationMethod} from "./Setup";

const WIDTH = '100px';
const PHONE_WIDTH = '50px';

interface WrapperProps {
    columns: number,
    [key:string]: any
}
const Wrapper = styled(({columns, ...props}:WrapperProps) => {
    return (
        <div {...props}/>
    )
})`
  max-width: ${({theme:{width}}) => width};
  
  display: grid;
  grid-template-columns: ${({columns}) => new Array(columns+1).join(`${WIDTH} `).trim()};
  grid-column-gap: 32px;
  
  position: relative;
  margin: 0 auto 3rem;
  
  @media (max-width: 480px) {
      grid-template-columns: ${({columns}) => new Array(columns+1).join(`${PHONE_WIDTH} `).trim()};
  }
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({theme:{accent}}) => accent};
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  color: ${({theme:{accent}}) => accent};
  font-weight: bold;
  font-size: 125%;
  margin-bottom: .5rem;
  position: relative;  
`;

const Label = styled.div`
  color: ${({theme:{lightBlue}}:any) => lightBlue};
  font-size: 12px;
`;

const Step = styled(Link)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  
  &.active ${Circle} {
    background-color: ${({theme:{lightBlue}}:any) => lightBlue};
    color: #fff;
  }
  
  &:not(:last-child) ${Circle}::after {
    content: '';
    position: absolute;
    top: 19px;
    left: 40px;
    width: 125px;
    height: 4px;
    background: #fff;
    
    @media (max-width: 480px) {
      width: 50px;
    }
  }
  
  &.active ${Label} {
    color: #fff;
  }
`;

function normalize(label: string) {
    return label.replace(/\s+/g, '-').toLowerCase();
}

const Steps = () => {
    const step = document.location.pathname.replace(/^.*\//, '');

    const steps = [
        'Purchase Profile'
    ];

    const verificationMethod:VerificationMethod = getVerificationMethod();

    if (verificationMethod & VerificationMethod.Phone)
        steps.push('Verify Phone');
    if (verificationMethod & VerificationMethod.Email)
        steps.push('Verify Email');

    if (sessionStorage.getItem(INVITE_AN_AGENT_KEY)){
//        steps.push('Invite an Agent');
    } else {
        steps.push(
            'Select an Agent',
//            'Confirm Agent'
        );
    }

    steps.push('Review');

    return (
        <Wrapper columns={steps.length}>
            {steps.map((label, i) => {
                const to = normalize(label);
                const className = to === step ? 'active' : '';

                return (
                    <Step key={label} className={className} to={to}>
                        <Circle>{i + 1}</Circle>
                        <Label>{label}</Label>
                    </Step>
                );
            })}
        </Wrapper>
    )
};

export default Steps;