import gql from "graphql-tag";

import {fragments} from './Viewer';

export default gql`
    mutation UpdateInquiry($input: UpdateInquiryInput!) {
        updateInquiry(input: $input) {
            inquiry {
                nodeId
                details
                detailsConfirmed
            }
            
            query {
                viewer {
                    nodeId
                    ...ViewerConsumer
                }
            }
        }
    }
    
    ${fragments.consumer}
`;