import {useQuery} from "@apollo/client";

import ViewerQuery, {ConsumerViewerQuery, ProviderViewerQuery} from '../gql/Viewer';
import {Viewer} from "../gql/types/Viewer";
import {useEffect} from "react";
import {getJWT, useClearStorage} from "../config";
import {ConsumerViewer} from "../gql/types/ConsumerViewer";
import {ProviderViewer} from "../gql/types/ProviderViewer";

export default function useViewer() {
    const {data, loading, error, refetch} = useQuery<Viewer>(ViewerQuery);
    const viewer = data?.viewer;
    const clearStorage = useClearStorage();

    const jwt = getJWT();

    useEffect(() => {
        if (loading || error)
            return;

        if (jwt && !viewer)
            clearStorage().then();
    }, [viewer, loading, error, jwt, clearStorage]);

    return {loading, viewer, error, refetch};
}

export function useConsumerViewer() {
    const {data, loading, error, refetch} = useQuery<ConsumerViewer>(ConsumerViewerQuery);
    const viewer = data?.viewer;
    const clearStorage = useClearStorage();

    const jwt = getJWT();

    useEffect(() => {
        if (loading || error)
            return;

        if (jwt && !viewer)
            clearStorage().then();
    }, [viewer, loading, error, jwt, clearStorage]);

    return {loading, viewer, error, refetch};
}

export function useProviderViewer() {
    const {data, loading, error, refetch} = useQuery<ProviderViewer>(ProviderViewerQuery);
    const viewer = data?.viewer;
    const clearStorage = useClearStorage();

    const jwt = getJWT();

    useEffect(() => {
        if (loading || error)
            return;

        if (jwt && !viewer)
            clearStorage().then();
    }, [viewer, loading, error, jwt, clearStorage]);

    return {loading, viewer, error, refetch};
}

export function useConsumerLeads() {
    const {loading, viewer, error} = useConsumerViewer();

    const leads = viewer?.consumer?.leads?.nodes;

    return {loading, leads, error};
}

export function useConsumerInquiries() {
    const {loading, viewer, error} = useConsumerViewer();

    const inquiries = viewer?.consumer?.inquiries?.nodes;

    return {loading, inquiries, error};
}

export function useConsumerMatches() {
    const {loading, viewer, error} = useConsumerViewer();

    const inquiries = viewer?.consumer?.inquiries?.nodes;

    const matches = inquiries && inquiries
        .filter((inquiry) => inquiry && !(inquiry?.leads?.nodes?.length));

    return {loading, matches, error};
}