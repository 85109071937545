import React, {Suspense} from 'react'
import {render} from 'react-dom'
import * as serviceWorker from './serviceWorker';

import {geoLookup, tracking} from './config';
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import Spinner from "./components/Spinner";

geoLookup().then();

render(
    <Suspense fallback={<Spinner/>}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Suspense>,

    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

(() => {
    if (tracking.gtm) {
        (function(w:any,d,s,l,i){
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            });

            const f:any = d.getElementsByTagName(s)[0],
                j:any = d.createElement(s),
                dl = l !== 'dataLayer' ? `&l=${l}` : '';

            j.async=true;
            j.src= `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
            f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer',tracking.gtm);
    }
})();