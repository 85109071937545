import {useLocation, useParams} from "react-router";
import useRedirect from "../lib/use-redirect";

interface Props {
    campaign: string
}

const CampaignRedirect = () => {
    const redirect = useRedirect();
    const {campaign} = useParams<Props>();
    const {pathname, search} = useLocation();

    const lander = pathname.match(/^\/a\//) ? '/join-now' : '/join-now'

    const to = `${lander}${search ? `${search}&` : '?'}c=${encodeURIComponent(campaign)}`;

    redirect(to);

    return null;
}

export default CampaignRedirect;