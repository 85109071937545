import {useEffect} from "react";

export let recaptchaToken = '';

(() => {
    const script = document.createElement('SCRIPT');
    script.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=6LeXRbgZAAAAAPPYBhqNmGpB41-xD-gkuN6DqirU');
    document.body.appendChild(script);

    const css = `
      body .grecaptcha-badge {
          transform: translateX(100vw);
      }

      body.show-recaptcha-badge .grecaptcha-badge {
          transform: translateX(0);
      }
    `;
    const style:any = document.createElement('STYLE');
    style.type = 'text/css';

    if (style.styleSheet)
        style.stylesheet.cssText = css;
    else
        style.appendChild(document.createTextNode(css));

    document.body.appendChild(style);
})();

function updateRecaptchaToken() {
    getRecaptchaToken()
        .then((token:any) => recaptchaToken = token);
}

export default function useRecaptcha() {
    useEffect(() => {
        if (document.body.classList.contains('show-recaptcha-badge'))
            return;

        updateRecaptchaToken();

        document.body.classList.add('show-recaptcha-badge');

        const interval = window.setInterval(updateRecaptchaToken, 90 * 1000); // update every 90 seconds

        return () => {
            document.body.classList.remove('show-recaptcha-badge');
            window.clearInterval(interval);
            recaptchaToken = '';
        }
    }, []);
};

const w:any = window;

export const getRecaptchaToken = () => {
    return new Promise((resolve, reject) => {
        const execute = () => {
            if (w.grecaptcha) {
                w.grecaptcha.ready(() => {
                    w.grecaptcha.execute('6LeXRbgZAAAAAPPYBhqNmGpB41-xD-gkuN6DqirU', {action: 'submit'})
                        .then(resolve)
                        .catch(reject);
                });
            }
            else {
                w.setTimeout(execute, 25);
            }
        };

        execute();
    });
};
