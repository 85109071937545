import {
    Viewer_viewer_contacts
} from "../gql/types/Viewer";

export function findContact(contacts:Viewer_viewer_contacts|null, type:string, field:string) {
    if (!(contacts && contacts.nodes))
        return null;

    const contact = contacts.nodes.find(contact => !!contact && contact.type === type);

    if (!contact)
        return null;

    return contact.value[field];
}

export function normalizeUUID(string:string) {
    return string.replace(/^(.{8})(.{4})(.{4})(.{4})(.{12})$/, '$1-$2-$3-$4-$5'); // format as UUID
}

export function isDirty(original:any, updated:any) {
    const originalFields = Object.keys(original||{});
    const updatedFields = Object.keys(updated||{});

    if (originalFields.length !== updatedFields.length)
        return true;

    for (let i = originalFields.length - 1; i >= 0; i--) {
        const k = originalFields[i];

        if (original[k] !== updated[k])
            return true;
    }

    return false;
}

export const STATES:any = {
    "AL": "Alabama",
    "AK": "Alaska",
//    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
//    "DC": "District Of Columbia",
    "DC": "Washington DC",
//    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
//    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
//    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
//    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
//    "PW": "Palau",
    "PA": "Pennsylvania",
//    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
//    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
};

export const EXP_MONTH = {
    '1': 'January',
    '2': 'February',
    '3': 'March',
    '4': 'April',
    '5': 'May',
    '6': 'June',
    '7': 'July',
    '8': 'August',
    '9': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December'
};
