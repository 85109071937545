import React, {lazy, useEffect, useState} from 'react';
import {Switch, Route} from 'react-router-dom';

import Logout from './components/Logout';
import Link from './components/Link';
import {Redirect} from "react-router";

import CampaignRedirect from "./components/CampaignRedirect";

const Home = lazy(() => import('./pages/Home/Home'));
const Login = lazy(() => import('./pages/Login'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const HelpCenter = lazy(() => import('./pages/HelpCenter'));
const TermsAndConditions = lazy(() => import('./pages/Agreements/TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('./pages/Agreements/PrivacyPolicy'));
const OurStory = lazy(() => import('./pages/OurStory'));
// const Kipp = lazy(() => import('./pages/Team/Kipp'));
// const Property = lazy(() => import('./pages/Property'));
const InviteAnAgent = lazy(() => import('./pages/InviteAnAgent'));
const Wizard = lazy(() => import('./pages/Wizard'));
const HowItWorks = lazy(() => import('./pages/HowItWorks'));
const Jump = lazy(() => import('./pages/Jump'));
const PlatinumAgents = lazy(() => import('./pages/PlatinumAgents'));
const AmExLandingPage = lazy(() => import('./pages/LandingPages/AmExLandingPage'));
const ThankYou = lazy(() => import('./pages/Wizard/ThankYou'));
const ApplyNow = lazy(() => import('./pages/ApplyNow'));
const MiaHome = lazy(() => import('./pages/MiaHome'));
const TpgLto = lazy(() => import('./pages/LandingPages/TpgLto'));
const TpgLtoTerms = lazy(() => import('./pages/LandingPages/TpgLto/LtoTerms'));

const LTO_END_TIMESTAMP = new Date('2022-01-01').getTime();

const Routes = () => {
    const [tpgLtoActive, setTpgLtoActive] = useState(Date.now() < LTO_END_TIMESTAMP);

    useEffect(() => {
        if (tpgLtoActive) {
            const timeout = window.setTimeout(() => setTpgLtoActive(false), LTO_END_TIMESTAMP - Date.now());
            return () => window.clearTimeout(timeout);
        }
    }, [tpgLtoActive, setTpgLtoActive]);

    return (
        <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/join-now" component={Home}/>
            <Route exact path="/join-now/:city" component={Home}/>
            <Route exact path="/earn-points" component={Home}/>
            <Route exact path="/earn-points/:city" component={Home}/>
            <Route exact path="/top-agents-and-rewards" component={Home}/>
            <Route exact path="/top-agents-and-rewards/:city" component={Home}/>
            <Route exact path="/rewards" component={Home}/>
            <Route exact path="/rewards/:city" component={Home}/>
            <Route exact path="/sonoran" component={Home}/>

            <Route path="/login" component={Login}/>
            <Route path="/logout" component={Logout}/>
            <Route path="/dashboard" component={Dashboard}/>
            <Route path="/about" component={AboutUs}/>
            <Route path="/l/:id" component={Link}/>
            <Route path="/help-center" component={HelpCenter}/>
            <Route path="/our-story" component={OurStory}/>
            <Route path="/rbn/real-buyer-network-rewards" component={OurStory}/>
            <Route path="/agreements/terms-and-conditions" component={TermsAndConditions}/>
            <Route path="/agreements/privacy-policy" component={PrivacyPolicy}/>
            <Route path="/invite-an-agent" component={InviteAnAgent}/>
            <Route exact path="/platinum-agents" component={PlatinumAgents}/>
            <Route path="/platinum-agents/:city" component={PlatinumAgents}/>
            <Route path="/apply-now" component={ApplyNow}/>

            { /*<Route path="/properties/:id/:address" component={Property}/> */ }

            <Route path="/a/:campaign" component={CampaignRedirect}/>
            <Route path="/j/:campaign" component={CampaignRedirect}/>

            <Route exact path="/wizard" component={Wizard}/>
            <Route path="/wizard/thank-you" component={ThankYou}/>
            <Route path="/wizard/:step" component={Wizard}/>

            <Redirect exact path="/apply" to="/join-now"/>
            <Redirect exact path="/agents" to="/apply-now"/>
            <Redirect exact path="/terms" to="/agreements/terms-of-use"/>
            <Redirect exact path="/fb" to="/join-now?c=fb"/>
            <Redirect exact path="/agreements/terms-of-use" to="/agreements/terms-and-conditions"/>

            <Route path="/amex" component={AmExLandingPage}/>
            <Route path="/fb/amex" component={AmExLandingPage}/>
            <Route exact path="/join" component={AmExLandingPage}/>
            <Route exact path="/join/:city" component={AmExLandingPage}/>
            <Route exact path="/earn-rewards" component={AmExLandingPage}/>
            <Route exact path="/earn-rewards/:city" component={AmExLandingPage}/>

            <Route exact path="/how-it-works" component={HowItWorks}/>

            <Route path="/jump" component={Jump}/>

            <Route exact path="/thepointsguy" component={Home}/>
            <Route exact path="/thepointsguy/:city" component={Home}/>

            <Route exact path="/thepointsguy-lto" component={tpgLtoActive ? TpgLto : Home}/>
            <Route exact path="/thepointsguy-lto/terms" component={TpgLtoTerms}/>
            <Route exact path="/thepointsguy-lto/:city" component={tpgLtoActive ? TpgLto : Home}/>

            <Route exact path="/mia-home" component={MiaHome}/>

            <Redirect to="/"/>
        </Switch>
    );
};

export default Routes;
