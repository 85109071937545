import {version} from "./version";

let server = {
    'local': `https://dev.api.joinrbn.com`,
    'dev': `https://dev.api.joinrbn.com`,
    'staging': `https://api.joinrbn.com`,
    'production': `https://api.joinrbn.com`,
}[version] || `https://api.joinrbn.com`;

let endpoint = `${server}/api`;

export const api = {
    server,
    endpoint
};