// noinspection TypeScriptValidateTypes

import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { setContext } from '@apollo/client/link/context';

import {clearJWT, JWT_KEY} from './authentication';
import {recaptchaToken} from "../lib/use-recaptcha";
import {api} from "./api";

const fetch = typeof window === 'undefined' ?  require('node-fetch') : window.fetch;

const httpLink = createHttpLink({
    uri: `${api.server}/graphql`,
    fetch
});

const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );

    if (networkError) {
        console.error(`[Network error]: ${networkError}`);

        const e:any = networkError;

        if (e.statusCode === 401) {
            // clear JWT and reload
            clearJWT();
            document.location.reload();
        }
    }

    console.log({graphQLErrors, networkError});
});

const authLink = setContext((_, { headers: originalHeaders }) => {
    const headers = Object.assign({}, originalHeaders);

    // get the authentication token from local storage if it exists
    const jwt = localStorage.getItem(JWT_KEY);
    // return the headers to the context so httpLink can read them

    if (jwt) {
        const [,b64encoded,] = jwt.split('.');

        const {exp}: any = JSON.parse(atob(b64encoded));
        const expiresAt = exp && (Number(exp) * 1000);

        if (expiresAt < Date.now()) {
            // JWT is expired
            clearJWT();
            document.location.reload();
        }
        else {
            // console.log(`jwt expires at ${new Date(expiresAt)}`);
            Object.assign(headers, {Authorization: `Bearer ${jwt}`});
        }
    }

    if (recaptchaToken)
        Object.assign(headers, {'X-Recaptcha': recaptchaToken});

    return {headers};
});

const cache = new InMemoryCache({
    dataIdFromObject: (object:any) => object.nodeId || null
});

export const apolloClient = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache
});
