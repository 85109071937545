import gql from "graphql-tag";

import {fragments} from './Viewer';

export default gql`
    mutation CreateContact($input: CreateContactInput!) {
        createContact(input: $input) {
            contact {
                id
                nodeId
                type
                value
            }
            
            query {
              viewer {
                nodeId
                
                ...ViewerContacts
              }
            }
        }
    }

    ${fragments.contacts}
`;