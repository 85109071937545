import React from 'react';
import Step from "./Step";
import Content from "./Content";
import TopMessage from "./TopMessage";
import {useConsumerViewer} from "../../../../lib/use-viewer";
import Spinner from "../../../../components/Spinner";
import {useMutation} from "@apollo/client";
import {UpdateInquiry as UpdateInquiryResult, UpdateInquiryVariables} from "../../../../gql/types/UpdateInquiry";
import UPDATE_INQUIRY from "../../../../gql/UpdateInquiry";
import {findContact} from "../../../../lib/misc";
import styled from "styled-components";
import {Button, Buttons} from "../../../../components/form";
import Agreement from "./Agreement";
import {useProviderById} from "../../../../lib/use-provider";
import useRedirect from "../../../../lib/use-redirect";
import {
    CreateInvitation as CreateInvitationResult,
    CreateInvitationVariables
} from "../../../../gql/types/CreateInvitation";
import CREATE_INVITATION from "../../../../gql/CreateInvitation";
import Name from "../../../../components/Name";
import sessionStorage from '../../../../lib/session-storage';
import {INVITE_AN_AGENT_KEY} from "./Setup";
import Photo from "../../../../components/Photo";

const Data = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: .5rem;
  
  h3 {
    font-weight: bold;
    grid-column: 1/3;
    text-align: left;
    margin: 2rem 0 0 0;
  }
  
  label {
    text-align: right;
  }
  
  span {
    text-align: left;
  }
`;
function row(label:string|any, value:string) {
    if (typeof label === 'string') {
        return (
            <>
                <label>{label}:</label>
                <span>{value}</span>
            </>
        );
    }
    else {
        return (
            <>
                {label}
                <span>{value}</span>
            </>
        );
    }
}

function useInviteAgent(fields:any) {
    const [createInvitation] = useMutation<CreateInvitationResult, CreateInvitationVariables>(CREATE_INVITATION);

    if (!(fields && fields.email))
        return null;

    return () => {
        createInvitation({
            variables: {
                input: {
                    invitation: Object.assign({
                            info: {
                                type:'agent'
                            }
                        },
                        fields)
                }
            }
        })
            .then(() => {})
    };
}

const Message = styled(TopMessage)`
  margin: 0 0 0 -4rem;
  
  p:last-child {
    margin-bottom: 0;
  }
`;

const AgentPhoto = styled(Photo).attrs({
    height:0,
    bw: 'bw'
})`
  min-height: 100px;
  margin-top: .125em;
`;

const AgentInfo = styled.div`
  text-align: left;
`;

const Review = () => {
    const redirect = useRedirect('If you leave now your profile will be incomplete.', /^\/dashboard/);

    const {viewer={firstName:'', lastName:'', contacts:null, consumer:null}} = useConsumerViewer();
    const inquiries = viewer &&
        viewer.consumer &&
        viewer.consumer.inquiries &&
        viewer.consumer.inquiries.nodes;
    const inquiry = inquiries &&
        inquiries.find(inquiry => !!(inquiry && !inquiry.detailsConfirmed));

    const [confirmInquiryMutation] = useMutation<UpdateInquiryResult, UpdateInquiryVariables>(UPDATE_INQUIRY, {variables: {input: {nodeId: inquiry ? inquiry.nodeId : '', patch: {detailsConfirmed: new Date()}}}});

    const {provider:agent} = useProviderById(inquiry && inquiry.details && inquiry.details.selectedAgent);

    const inviteAnAgentJSON = sessionStorage.getItem(INVITE_AN_AGENT_KEY);
    const inviteAgentFields = (inviteAnAgentJSON && JSON.parse(inviteAnAgentJSON)) || undefined;
    const inviteAgent = useInviteAgent(inviteAgentFields);


    if (!inquiry)
        return <Spinner/>;

    const email = viewer && findContact(viewer.contacts, 'email', 'address');
    const phone = viewer && findContact(viewer.contacts, 'phone', 'number');

    const blocks = [];

    const details = inquiry && inquiry.details;

    blocks.push(
        <React.Fragment key="contact">
            <h3>Your Contact Info</h3>
            {row('Name', `${viewer && viewer.firstName} ${viewer && viewer.lastName}`)}
            {row('Email', email)}
            {row('Phone', phone)}
        </React.Fragment>
    );

    if (details && details.buying) {
        const {city, state, price, timeframe} = details.buying;
        blocks.push(
            <React.Fragment key="buying">
                <h3>Your Purchase Profile</h3>
                {row('Looking In', `${city}, ${state}`)}
                {row('Price Range', price)}
                {row('Time Frame', timeframe)}
            </React.Fragment>
        );
    }

    if (details && details.selling) {
        const {address, city, state, zip} = details.selling;

        blocks.push(
            <React.Fragment key="selling">
                <h3>Your Existing Home Info</h3>
                {row('Address', address)}
                {row('City, State ZIP', `${city}, ${state} ${zip}`)}
            </React.Fragment>
        );
    }

    if (agent) {
        blocks.push(
            <React.Fragment key="agent">
                <h3>Your RBN Participating Agent</h3>
                <AgentPhoto src={agent.image && agent.image.url}/>
                <AgentInfo>
                    {agent.name}<br/>
                    {agent.phone}<br/>
                    {agent.email}<br/>
                </AgentInfo>
            </React.Fragment>
        )
    }

    if (inviteAgentFields && inviteAgentFields.email) {
        blocks.push(
            <React.Fragment key="invite-agent">
                <h3>Your Invited Agent</h3>
                {row('Name', `${inviteAgentFields.firstName} ${inviteAgentFields.lastName}`)}
                {row('Email', inviteAgentFields.email)}
                {phone && row('Phone', inviteAgentFields.phone)}
            </React.Fragment>
        )
    }

    const onSubmit = async (e:any) => {
        e.preventDefault();

        await confirmInquiryMutation();

        if (inviteAgent)
            await inviteAgent();
        /*else if (details.selectedAgent) {
            await hire({
                variables: {
                    input: {
                        providerId: details.selectedAgent
                    }
                }
            });
        }*/

        sessionStorage.clear();

        redirect('/dashboard/');
    };


    if (!viewer)
        return <Spinner/>;

    return (
        <Step heading="Review">
            <Content>
                <Message>
                    <p>
                        <Name>{viewer.firstName}</Name>,
                    </p>
                    <p>
                        We're almost done.
                    </p>
                    <p>
                        Please confirm the information below.
                    </p>
                    {agent && <div>
                        <p>
                            Once you hit the <strong>submit</strong> button, you'll connect your American Express
                            account and we'll let <strong><Name>{agent.name}</Name></strong> know that you've
                            selected {agent.gender === 'M' ? 'him' : 'her'}. Your final step will be to review your
                            profile on the RBN Dashboard where everything is summarized. We're also sending you an
                            email with instructions to setup your password.
                        </p>
                    </div>}
                </Message>

                <Data>
                    {blocks}
                </Data>

                <Buttons>
                    <Button variation="green" onClick={onSubmit}>Submit</Button>
                </Buttons>
                <Agreement button="submit"/>
            </Content>
        </Step>
    )
};

export default Review;
