import gql from 'graphql-tag';

import {fragments} from './Viewer';

export default gql`
    mutation DeleteContact($input: DeleteContactInput!) {
        deleteContact(input: $input) {
            query {
              viewer {
                nodeId
                
                ...ViewerContacts
              }
            }
        }
    }

    ${fragments.contacts}
`;