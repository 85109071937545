import styled from "styled-components";
import React from "react";

const Arrow = styled(({className}:{className?:string}) => {
    return (
        <svg className={className} preserveAspectRatio="none" viewBox="0 0 60 30" xmlns="http://www.w3.org/2000/svg">
            <polygon points="0,0 30,30 60,0" />
        </svg>
    );
})`
  height: 30px;
  width: 60px;
  fill: currentColor;
`;

const TransparentArrow = styled(({className}:{className?:string}) => {
    return (
        <svg className={className} preserveAspectRatio="none" viewBox="0 0 60 30" xmlns="http://www.w3.org/2000/svg">
            <polygon points="0,0 30,30 60,0 60,30 0,30" />
        </svg>
    );
})`
  height: 30px;
  width: 60px;
  fill: currentColor;
`;

const Bottom = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto 1fr;
`;

interface ArrowProps {
    className?: string,
    color?: string,
    backgroundColor?: string,
    hideOnMobile?: boolean
}

export default styled(({color, className}:ArrowProps) => (
    <Bottom className={className}>
        <div/>
        {color === 'transparent' ? <TransparentArrow/> : <Arrow/>}
        <div/>
    </Bottom>
))`
  & > div {
    background-color: ${({backgroundColor}) => backgroundColor};
  }
  
  color: ${({color, backgroundColor}) => color !== 'transparent' ? color : backgroundColor};
  
  @media (max-width: 480px) {
    display: ${({hideOnMobile}) => hideOnMobile ? 'none' : 'grid'};
  }
`;