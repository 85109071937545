import styled from 'styled-components';

import icon from './message-icon.svg';

const TopMessage = styled.div`
  position: relative;
  z-index: 1;
  text-align: justify;
  margin: 0 0 2rem -4rem;
  padding-left: 4rem;
  background: url(${icon}) top left no-repeat;
  background-size: 3rem 3rem;
  min-height: 3rem;
  
  @media (max-width: 480px) {
    margin: 0 0 2rem;
    padding: 0;
    background-image: none;
  }
  
  @media (max-width: 800px) {
    background-image: none;
  }
`;

export default TopMessage;
