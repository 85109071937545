import {Viewer_viewer_contacts_nodes} from "../gql/types/Viewer";
import {useMutation} from "@apollo/client";
import {SendVerificationCode, SendVerificationCodeVariables} from "../gql/types/SendVerificationCode";
import SEND_VERIFICATION_CODE from "../gql/SendVerificationCode";
import {useCallback} from "react";
import useViewer from "./use-viewer";

const useSendVerificationCode = (contact:Viewer_viewer_contacts_nodes|null|undefined) => {
    const [sendVerificationCode] = useMutation<SendVerificationCode, SendVerificationCodeVariables>(SEND_VERIFICATION_CODE, {
        variables: {input: {contactId: (contact && contact.id) || ''}}
    });
    const {refetch:refetchViewer} = useViewer();

    const sendCode = useCallback((force?:boolean) => {
        if (!contact || contact.verifiedAt)
            return;

        if (contact.verificationCodeSent) {
            if (!force)
                return; // already sent and user didn't request resend

            const sent = new Date(contact.verificationCodeSent);

            const elapsed = Date.now() - sent.getTime();

            if (elapsed < 60*1000 /* 60 seconds */) {
                //console.log(`last send was only ${(elapsed / 1000).toFixed(0)} seconds ago. not resending verification code.`);
                return;
            }
        }

        sendVerificationCode().then(refetchViewer);
    }, [contact, sendVerificationCode, refetchViewer]);

    return sendCode;
}

export default useSendVerificationCode;