import gql from "graphql-tag";

import {fragments} from './Viewer';

export default gql`
    mutation CreateInquiry($input: CreateInquiryInput!) {
        createInquiry(input: $input) {
            inquiry {
                nodeId
                details
            }
            
            query {
                viewer {
                    nodeId
                    ...ViewerConsumer
                }
            }
        }
    }
    
    ${fragments.consumer}
`;