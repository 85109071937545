import React from 'react';
import {RouteComponentProps} from "react-router";
import useRedirect from "../lib/use-redirect";
import {useQuery} from "@apollo/client";
import LINK_QUERY from '../gql/Link';
import {Link as LinkResult, LinkVariables} from '../gql/types/Link'
import Spinner from "./Spinner";
import {normalizeUUID} from "../lib/misc";

interface Props {
    id: string
}

const Link = (props:RouteComponentProps<Props>) => {
    const {match:{params:{id:strippedId}}} = props;
    const id = normalizeUUID(strippedId);

    const redirect = useRedirect();

    const {loading, data, error} = useQuery<LinkResult, LinkVariables>(LINK_QUERY, {
        variables: {
            input: id
        }
    });

    if (data && data.linkById) {
        console.log('Link', data);
        const {linkById:{path, data:state}} = data;
        redirect(path, state);
    }
    else if (error) {
        console.error(error);
        redirect('/');
    }
    else if (!loading) {
        console.error(`invalid link ${id}`);
        redirect('/');
    }

    return <Spinner/>;
};

export default Link;