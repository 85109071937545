import gql from "graphql-tag";

export default gql`
    mutation VerifyContact($input: VerifyContactInput!, $contactId: UUID!) {
        verifyContact(input: $input) {
            verifyContactResult {
                verified
                message
            }
            
            query {
                contactById(id: $contactId) {
                    nodeId
                    verifiedAt
                }
            }
        }
    }
`;