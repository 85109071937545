import {version} from "./version";

const TRACKING:any = {
    local: {},

    production: {
        gtm: 'GTM-MRCJNLF'
    }
};

const _tracking:any = TRACKING[version] || TRACKING.local;

export const tracking = _tracking;
