import React, {FormEvent} from 'react';
import Step from "./Step";
import styled from "styled-components";
import Content from "./Content";
import TopMessage from "./TopMessage";
import {useConsumerViewer} from "../../../../lib/use-viewer";
import {Button, Buttons, Input} from "../../../../components/form";
import Error from "../../../../components/Error";
import {PageProps} from "../../../../lib/create-track";
import {useInviteAgent} from "../../../../lib/use-invite-agent";
import sessionStorage from '../../../../lib/session-storage';
import {INVITE_AN_AGENT_KEY} from "./Setup";

const InviteAnAgent = ({page:{next}}:PageProps) => {
    const {viewer} = useConsumerViewer();
    const {fields: {firstName, lastName, email, phone}, updateField, error} = useInviteAgent(JSON.parse(sessionStorage.getItem(INVITE_AN_AGENT_KEY)||'{}'));
    const inquiries = viewer &&
        viewer.consumer &&
        viewer.consumer.inquiries &&
        viewer.consumer.inquiries.nodes;
    const inquiry = inquiries &&
        inquiries.find(inquiry => !!(inquiry && !inquiry.detailsConfirmed));
    const {city, state} = inquiry && inquiry.details && inquiry.details.buying;

    const details = inquiry && inquiry.details;

    const valid = firstName && lastName && email;

    const submitHandler = (event:FormEvent) => {
        event.preventDefault();

        if (!valid)
            return;

        sessionStorage.setItem(INVITE_AN_AGENT_KEY, JSON.stringify({
            firstName,
            lastName,
            email,
            phone
        }));

        next({valid:true});
    };

    const skip = (event:FormEvent) => {
        event.preventDefault();

        sessionStorage.setItem(INVITE_AN_AGENT_KEY, JSON.stringify({skipped:true}));

        next({valid:true});
    };

    return (
        <Step heading="Invite an Agent">
            <Content>
                <TopMessage>
                    <p>
                        Hi {viewer && viewer.firstName}, RBN is expanding to <strong>{city}, {state}</strong> soon. You can invite an agent from the form
                        below or continue your Membership application to be notified when we launch in <strong>{city}</strong>.
                    </p>
                    <p>
                        RBN respects existing contractual obligations with agents and their clients. Customers working with an existing agents are not eligible to earn RBN Rewards.
                    </p>
                    {details.currentlyWorkingWithAnAgent && (
                        <p>
                            NOTE:  RBN respects existing contractual obligations with agents and their clients. Customers with existing agent agreements are not eligible to earn RBN Rewards.
                        </p>
                    )}
                </TopMessage>

                <Fields
                    onSubmit={submitHandler}
                    className="refer-agent"
                >
                    <Input placeholder="Agent First Name" name="firstName" value={firstName} onChange={updateField} type="text" required={true}/>
                    <Input placeholder="Agent Last Name" name="lastName" value={lastName} onChange={updateField} type="text" required={true}/>
                    <Input placeholder="Agent Email Address" name="email" value={email} onChange={updateField} type="email" required={true}/>
                    <Input placeholder="Agent Phone Number" name="phone" value={phone} onChange={updateField} type="tel"/>
                    <Error error={error}/>
                    <Buttons>
                        <Button variation="white" onClick={skip}>Skip This Step</Button>
                        <Button variation="green" disabled={!valid}>Invite Now</Button>
                    </Buttons>
                </Fields>

            </Content>
        </Step>
    )
};

export default InviteAnAgent;


const Fields = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  
  padding: 1rem;
  
  label {
    margin: 0;
  }
  
  & > div {
    grid-column: 1/3;
  }
  
  input {
    margin: 0;
  }
  
  @media (max-width: 480px) {
    label {
      grid-column: 1/3;
    }
    
    ${Buttons} {
      justify-content: space-between;
    }
  }
`;
