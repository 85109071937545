import gql from 'graphql-tag';

import {fragments} from './Viewer';

export default gql`
mutation CreateInvitation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
        invitation {
            nodeId
        }
        
        query {
            viewer {
                nodeId
                ...Invitations
            }
        }
    }
}

${fragments.invitations}
`;