import React from "react";
import styled from "styled-components";

import {getAgentStats, useProviderById} from "../../lib/use-provider";
import Spinner from "../Spinner";
import Photo from "../Photo";
import {Button, Buttons, LinkButton} from "../form";
import {FaCheck} from "react-icons/fa";
import Bio from "../Bio";

interface AgentProps {
    id: string,
    button?: any,
    hired?: boolean,
    className?: string,
    hidePhone?: boolean
}

export const AgentName = styled.div`
    padding: 1rem;
    overflow: hidden;
    
    h3 {
        margin: 0;
        font-size: 18px;
    }
    
    a:first-child {
      margin-right: 1em;
      text-decoration: none;
    }
    
    h4 {
      font-size: 16px;
      margin: 0 0 .25em;
    }
    
    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
        
    @media (max-width: 400px) {
      h3:first-child {
          display: grid;
          grid-template-columns: 1fr;
          
          div:last-child {
            margin: 0;
          }
      }
    }
`;

const Stats = styled.div`
    color: #333;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 1px;
`;

const Stat = styled.div`
  padding: 1rem 0;
  text-align: center;
  background: #e1e1e1;
  
  & h3:first-child {
    display: block;
    margin: 0 0 4px;
    font-size: 32px;
  }
  
  @media(max-width: 480px) {
      font-size: 12px;
      
      & h3:first-child {
        font-size: 24px;
      }
  }
`;

const BioWrapper = styled.div`
  padding: 1rem;
`;

export const AgentWrapper = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    margin: 1rem 0 2rem;
    background-color: #fff;
    text-align: left;
    border: 2px solid #ccc;
    
    ${Photo} {
        flex-shrink: 0;
        width: 200px;
        grid-row: 1/3;
        
        @media(max-width: 480px) {
          width: 150px;
          grid-row: 1/2;
        }
    }
    
    ${Stats} {
      @media(max-width: 480px) {
        grid-column: 1/3;
      }
    }
    
    ${BioWrapper} {
      grid-row: 3/4;
      grid-column: 2/3;
      
      @media(max-width:480px) {
        grid-row: 3/4;
        grid-column: 1/3;
      }
    }
    
    ${Buttons} {
      padding: 0 1rem 1rem;
      flex-direction: column;
      justify-content: flex-start;
      
      @media(max-width: 480px) {
        grid-column: 1/3;
        flex-direction: row;
        justify-content: space-between;
    }
    
    ${Button}, ${LinkButton} {
      margin: 0 0 .5rem;
      text-transform: uppercase;
      padding: .25rem .5rem;
      font-size: 14px;
      width: 100%;
      
      @media(max-width: 480px) {
        margin: 0;
        width: auto;
      }
    }
    
    ${Button}:last-child {
      margin-bottom: 0;
    }
    
    h3 {
        color: inherit;
        display: flex;
        align-items: center;
        line-height: 30px;
        font-size: 20px;
        font-weight: bold;
    }
    
    h3 > :last-child {
      margin-left: .5rem;
    }
        
    font-size: 14px;
`;

const Hired = styled(props => {
    return (
        <div {...props}>
            <FaCheck color="#0c0"/>
            <span>Hired</span>
        </div>
    )
})`
  font-size: 24px;
  text-align: right;
  grid-column: 1/3;
  padding: 0 .5rem;
  
  span {
    margin-left: .5rem;
  }
`;

const Agent = styled(({id, button, hired, hidePhone, className}:AgentProps) => {
    const {provider:agent, loading} = useProviderById(id);

    if (loading)
        return <AgentWrapper key="spinner"><Spinner/></AgentWrapper>;

    if (agent) {
        const brokerage = agent.info && agent.info.brokerage && agent.info.brokerage.name;

        const stats = getAgentStats(agent);

        return (
            <AgentWrapper key="agent-info" className={className}>
                <Photo src={agent.image && agent.image.url} height={0} bw="bw"/>
                <AgentName>
                    <h3>{agent.name}</h3>
                    <h4>
                        {agent.title} at {brokerage}
                    </h4>
                    {!hidePhone && <div>
                        <a href={`tel:${agent.phone}`}>{agent.phone}</a>
                    </div>}
                </AgentName>
                <Stats>
                    <Stat>
                        <h3>{stats.transactionCount}</h3>
                        Propert{stats.transactionCount === 1 ? 'y' : 'ies'} Sold<br/>in the Last 3 Years
                    </Stat>
                    <Stat>
                        <h3>{stats.years}</h3>
                        Year{stats.years === 1 ? '' : 's'} Experience
                    </Stat>
                    <Stat>
                        <h3>{stats.averagePrice}</h3>
                        Average Price
                    </Stat>
                </Stats>
                <BioWrapper>
                    <Bio provider={agent}/>
                </BioWrapper>
                <Buttons>
                    <LinkButton to={`/p/${agent.slug}`} variation="light-blue">View Profile</LinkButton>
                    {button}
                </Buttons>
                {hired && <Hired/>}
            </AgentWrapper>
        );
    }

    return null;
})`
`;

export default Agent;