import React, {useEffect, useRef, useState} from 'react';
import {FaPencilAlt} from 'react-icons/fa';

import {Provider_provider} from "../gql/types/Provider";
import {Viewer_viewer_provider} from "../gql/types/Viewer";
import styled from "styled-components";
import {Button, Buttons, TextArea} from "./form";
import {useUpdateProvider} from "../lib/use-provider";
import {useProviderViewer} from "../lib/use-viewer";
import {ProviderById_providerById} from "../gql/types/ProviderById";

interface Props {
    className?: string,
    provider: Provider_provider|Viewer_viewer_provider|ProviderById_providerById|null|undefined,
    alwaysEdit?: boolean
}

const Ellipsis = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    line-height: 1.5em;
    padding-left: 2em;
    color: #666;
    cursor: pointer;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    
    &::after {
      content: '[\u2026]';
      font-size: .75em;
      font-family: sans-serif;
    }
`;

interface ClippedBioProps {
    bio: string,
    className?: string
}

const ClippedBio = styled(({bio, className}:ClippedBioProps) => {
    const [clipped, setClipped] = useState(true);
    const [more, setMore] = useState(false);
    const ref = useRef(null);

    const checkOverflow = () => {
        if (!(ref && ref.current))
            return;

        const div:any = ref && ref.current;

        if (!div)
            return;

        setMore(div.offsetHeight + 8 < div.scrollHeight);
    };

    useEffect(checkOverflow, [bio]);

    useEffect(() => {
        if (!(ref.current && clipped))
            return;

        let timeout = 0;

        const onResize = () => {
            if (timeout)
                clearTimeout(timeout);

            timeout = window.setTimeout(checkOverflow, 50);
        };

        typeof window !== 'undefined' && window.addEventListener('resize', onResize);

        return () => {
            typeof window !== 'undefined' && window.removeEventListener('resize', onResize);
        }
    }, [clipped, ref]);

    const paragraphs = bio
        .trim()
        .split(/[\r\n]+/)
        .map((p, i) => (
            <p key={i}>
                {p}
            </p>
        ));

    const unclip = (e:any) => {
        e.preventDefault();
        e.stopPropagation();
        setClipped(false);
    };

    return (
        <div className={`${className} ${clipped ? 'clipped' : ''}`} ref={ref}>
            {clipped && more && <Ellipsis onClick={unclip}/>}
            <div>
                {paragraphs}
            </div>
        </div>
    );
})`
  padding: 0;
  line-height: 1.5em;
  position: relative;
  overflow: hidden;
  
  &.clipped {
      max-height: 6em;
  }
  
  p {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
  
  p:first-child {
    margin-top: 0;
  }
  
  p:last-child {
    margin-bottom: 0;
  }
`;

const Bio = styled(({provider, className, alwaysEdit}:Props) => {
    const [editing, setEditing] = useState(!!alwaysEdit);
    const {viewer} = useProviderViewer();
    const _info = viewer?.provider?.info;
    const _bio = (_info && _info.bio)||'';
    const [bio, setBio] = useState(_bio);
    const updateProvider = useUpdateProvider();

    const editable = viewer?.provider?.nodeId === provider?.nodeId;

    const dirty = bio !== _bio;

    const onSave = () => {
        if (!(dirty && provider))
            return;

        const info = Object.assign({}, _info, {bio});

        updateProvider(provider.nodeId, {info}).then(() => setEditing(!!alwaysEdit || false));
    };

    if (editing) {
        return (
            <div className={className}>
                <TextArea value={bio} onChange={(e:any) => setBio(e.target.value)}/>
                <Buttons>
                    {!alwaysEdit && <Button variation="white" onClick={() => setEditing(false)}>Cancel</Button>}
                    <Button onClick={onSave} disabled={!dirty}>Save</Button>
                </Buttons>
            </div>
        )
    }
    else {
        return (
            <div className={className} onClick={() => editable && setEditing(true)}>
                {bio && <ClippedBio bio={bio}/>}
                {editable && <FaPencilAlt/>}
            </div>
        )
    }
})`
  position: relative;
  
  svg {
    font-size: 14px;
    position: absolute;
    top: 0;
    right: 0;
    color: #999;
    cursor: pointer;
  }
  
  textarea {
    height: 20em;
  }
`;

export default Bio;