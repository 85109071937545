import React from 'react';
import AuthenticatedPage, {AccountType} from "../../../../components/AuthenticatedPage";
import styles from "../../../../styles";
import Steps from "./Steps";
import Arrow from "../../../../components/Arrow";
import styled from "styled-components";
import {Buttons} from "../../../../components/form";

const Heading = styled.h1`
  color: #fff;
  text-align: center;
  padding-top: 2rem;
  font-weight: bold;
`;

const Content = styled.div`
  background: #fff;
  flex-grow: 1;
  
  p {
    text-align: justify;
  }
  
  ${Buttons} {
  }
  
  @media (max-width: 480px) {
    p {
      text-align: left;
    }
  }
`;


interface StepProps {
    heading: string,
    hideSteps?: boolean,
    children: any
}

const Step = ({heading, hideSteps, children}:StepProps) => {
    return (
        <AuthenticatedPage
            background={styles.accent}
            accountType={AccountType.Consumer}
            fullWidth={true}
        >
            <Heading>{heading}</Heading>
            {!hideSteps && <Steps/>}
            <Arrow backgroundColor="#fff" color="transparent"/>
            <Content>
                {children}
            </Content>
        </AuthenticatedPage>
    );
};

export default Step;