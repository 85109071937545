import {useState} from "react";
import sessionStorage from './session-storage';

function useFields(fields:Array<string>, _defaultValues:any={}, sessionStorageKey?:string) {
    const defaultValues = Object.assign({}, _defaultValues);

    fields.forEach(field => {
        if (defaultValues[field] === undefined)
            defaultValues[field] = '';
    });

    if (sessionStorageKey) {
        const json = sessionStorage.getItem(sessionStorageKey);

        if (json) {
            const data = JSON.parse(json);

            Object.assign(defaultValues, data);
        }
        else {
            sessionStorage.setItem(sessionStorageKey, JSON.stringify(defaultValues));
        }
    }

    const [values, setValues] = useState(defaultValues);

    const updateField = (e:any, value?:any) => {
        const newValues = Object.assign({}, values);

        if (typeof e === 'string') {
            const key:string = e;

            Object.assign(newValues, {[key]: value});
        }
        else {
            if (!e.target)
                return console.error({e});

            if (!e.target.name) {
                console.error('input missing name attribute', e.target);
                return;
            }

            Object.assign(newValues, {[e.target.name]: e.target.value});
        }

        setValues(newValues);

        if (sessionStorageKey) {
            sessionStorage.setItem(sessionStorageKey, JSON.stringify(newValues));
        }
    };

    const updateFields = (update:any) => {
        const newValues = Object.assign({}, values, update);

        setValues(newValues);

        if (sessionStorageKey) {
            sessionStorage.setItem(sessionStorageKey, JSON.stringify(newValues));
        }
    };

    const reset = (e?:any) => {
        if (e && e.preventDefault)
            e.preventDefault();

        setValues(defaultValues);
    };

    const dirty = fields.reduce((dirty:boolean, key:string) => dirty || (values[key] !== defaultValues[key]), false);

    return {fields: values, updateField, updateFields, reset, dirty};
}

export default useFields;