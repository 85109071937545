import gql from "graphql-tag";

import {fragments} from "./Viewer";

export default gql`
    mutation UpdateAccount($input: UpdateAccountInput!) {
        updateAccount(input: $input) {
            query {
              viewer {
                nodeId
                firstName
                lastName
                onboardedBy
                
                ...ViewerAvatar
              }
            }
        }
    }
    
    ${fragments.avatar}
`;