import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {FaQuestionCircle as Hover} from 'react-icons/fa';

import Step from "./Step";
import Content from "./Content";
import {useConsumerViewer} from "../../../../lib/use-viewer";
import {Button, Buttons, Input, Select} from "../../../../components/form";
import useFields from "../../../../lib/use-fields";
import {useMutation} from "@apollo/client";
import {UpdateAccount, UpdateAccountVariables} from "../../../../gql/types/UpdateAccount";
import UpdateAccountQuery from '../../../../gql/UpdateAccount';
import {CreateInquiry as CreateInquiryResult, CreateInquiryVariables} from "../../../../gql/types/CreateInquiry";
import CREATE_INQUIRY from "../../../../gql/CreateInquiry";
import {UpdateInquiry as UpdateInquiryResult, UpdateInquiryVariables} from "../../../../gql/types/UpdateInquiry";
import UPDATE_INQUIRY from "../../../../gql/UpdateInquiry";
import {PageProps} from "../../../../lib/create-track";
import TopMessage from "./TopMessage";
import Name from "../../../../components/Name";
import {JoinBoxType, getBoxConfig} from "../../../../components/JoinBox";
import AutoCompleteLocation, {LocationOption} from "../../../../components/form/AutoCompleteLocation";
import {STATES} from "../../../../lib/misc";
import sessionStorage from '../../../../lib/session-storage';
import {CreateContact as CreateContactResult, CreateContactVariables} from "../../../../gql/types/CreateContact";
import CREATE_CONTACT from "../../../../gql/CreateContact";
import {DeleteContact as DeleteContactResult, DeleteContactVariables} from '../../../../gql/types/DeleteContact';
import DELETE_CONTACT from '../../../../gql/DeleteContact';
import {INVITE_AN_AGENT_KEY} from "./Setup";
import theme from '../../../../styles';

export const LOCATION_KEY = getBoxConfig(JoinBoxType.MEMBER).sessionKey;

const LocationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-column-gap: 1rem;
  
  grid-column: 1/3;
  
  input[name="zip"] {
    width: 5em;
  }
  
  @media (max-width: 480px) {
    grid-template-columns: 1fr auto;
    
    & > :first-child {
      grid-column: 1/3;
    }
  }
`;


const CheckboxWrapper = styled.div`
  margin-bottom: 1rem;
  
  grid-column: 1/3;
`;

const Fields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  
  text-align: left;
  
  @media (max-width:800px) {
    & > label {
      grid-column: 1/3;
    }
  }
`;

const ExistingHomeAddress = styled(Input)`
  grid-column: 1/3;
`;

const PurchaseProfile = ({page:{next}}:PageProps) => {
    const {viewer} = useConsumerViewer();
    const firstNameRef = useRef(null);

    useEffect(() => {
        const t:any = firstNameRef.current;

        if (t)
            t.focus();
    }, [firstNameRef]);

    const inquiry = viewer &&
        viewer.consumer &&
        viewer.consumer.inquiries &&
        viewer.consumer.inquiries.nodes &&
        viewer.consumer.inquiries.nodes.find(inquiry => !!(inquiry && !inquiry.detailsConfirmed));

    const email = viewer && viewer.contacts.nodes.find(contact => !!(contact && contact.type === 'email'));
    const verifiedEmail = !!(email && email.verifiedAt);

    const phone = viewer && viewer.contacts.nodes.find(contact => !!(contact && contact.type === 'phone'));
    const verifiedPhone = !!(phone && phone.verifiedAt);

    const buying = inquiry && inquiry.details && inquiry.details.buying;

    const sessionLocation = sessionStorage.getItem(LOCATION_KEY);

    const defaultLocation:LocationOption|undefined =
        (buying && buying.locationId && {
            id: buying.locationId,
            label: `${buying.city}, ${buying.state}`
        }) || (sessionLocation && JSON.parse(sessionLocation)) || undefined;

    const [location, setLocation] = useState<LocationOption|undefined>(defaultLocation);
    const [city, state] = (location && location.label && location.label.split(/,\s* /, 2)) || ['',''];

    const [_phone, setPhone] = useState( (phone && phone.value.number) || '');

    const [deleteContactMutation] = useMutation<DeleteContactResult, DeleteContactVariables>(DELETE_CONTACT, {variables: {input: {nodeId: (phone && phone.nodeId) || ''}}});
    const [createContactMutation] = useMutation<CreateContactResult, CreateContactVariables>(CREATE_CONTACT, {variables: {input: {
                contact: {
                    type: 'phone',
                    value: {
                        number: _phone
                    }
                }
            }}});

    const {fields: {firstName, lastName}, updateField: updateAccountField} = useFields(['firstName', 'lastName'],
        {
            firstName: viewer && viewer.firstName,
            lastName: viewer && viewer.lastName
        });

    const {fields, updateField, updateFields} = useFields(['price', 'city', 'state', 'timeframe'],
        Object.assign({
                price: PRICE_RANGES[2],
                city,
                state,
                timeframe: TIMES[0],
                locationId: location && location.id
            },
            inquiry && inquiry.details && inquiry.details.buying
        )
    );

    const [sell, setSell] = useState(!!(inquiry && inquiry.details && inquiry.details.selling));
//    const [currentlyWorkingWithAnAgent, setCurrentlyWorkingWithAnAgent] = useState(!!(inquiry && inquiry.details && inquiry.details.currentlyWorkingWithAnAgent));
    const [haveAmex, setHaveAmex] = useState(!!(inquiry && inquiry.details && inquiry.details.haveAmex));

    const {fields:sellFields, updateField:updateSellField} = useFields(['address', 'city', 'state', 'zip'],
        (inquiry && inquiry.details && inquiry.details.selling) || {});

    const validPhone = _phone.replace(/\D+/g, '').match(/^[2-9]\d{9}$/);

    const details = Object.assign({}, (inquiry && inquiry.details) || null, {
        buying: fields,
//        currentlyWorkingWithAnAgent: !!currentlyWorkingWithAnAgent,
        haveAmex: haveAmex
    });

    switch (details.buying.mortgageStatus) {
        case 'Pre-Qualified':
        case 'Pre-Approved':
            break;
        default:
            if (details.lender)
                delete details.lender;
            break;
    }

    if (sell)
        details.selling = Object.assign({}, details.selling, sellFields);
    else if (details.selling)
        delete details.selling;

    const [updateAccountMutation] = useMutation<UpdateAccount, UpdateAccountVariables>(UpdateAccountQuery, {
        variables: {
            input: {
                nodeId: (viewer && viewer.nodeId) || '',
                patch: {
                    firstName,
                    lastName
                }
            }
        }
    });
    const [createInquiryMutation] = useMutation<CreateInquiryResult, CreateInquiryVariables>(CREATE_INQUIRY, {variables: {input: {inquiry: {details}}}});
    const [updateInquiryMutation] =  useMutation<UpdateInquiryResult, UpdateInquiryVariables>(UPDATE_INQUIRY, {variables: {input: {nodeId: (inquiry && inquiry.nodeId) || '', patch: {details}}}});

    const valid = (
        firstName &&
        lastName &&
        fields && fields.locationId &&
        validPhone &&
        (!sell || (
            sellFields.address &&
            sellFields.city &&
            sellFields.state &&
            sellFields.zip && sellFields.zip.match(/^\d{5}$/)
        ))
    );

    const onContinue = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const proceed = () => {
            sessionStorage.removeItem(INVITE_AN_AGENT_KEY);
            next({valid:true});
        };

        if (!valid)
            return;

        updateAccountMutation()
            .then(() => {
                if (inquiry) {
                    return updateInquiryMutation().then(() => {});
                } else {
                    return createInquiryMutation().then(() => {});
                }
            })
            .then(async () => {
                if (phone && phone.value.number === _phone)
                    return; // same phone number

                if (phone)
                    await deleteContactMutation(); // remove old number

                return createContactMutation();
            })
            .then(proceed);
    };

    const onSelectLocation = (location:LocationOption|null) => {
        const locationId = location && location.id;
        const [city, state] = (location && location.label && location.label.split(/,\s* /, 2)) || ['',''];

        updateFields({
            locationId,
            city,
            state
        });

        setLocation(location || undefined);
    };

    return (
        <Step heading="Purchase Profile">
            <Content>
                <TopMessage>
                    {viewer && viewer.firstName && <p>
                        <Name>{viewer.firstName}</Name>,
                    </p>}
                    {(verifiedEmail || verifiedPhone) && <p>
                        Thank you for verifying your {verifiedPhone ? 'phone number' : ''}{verifiedEmail ? `${(verifiedPhone ? ' and ' : '')}email address` : ''}.
                    </p>}
                    <p>
                        Let's find out a little more about your next home purchase.
                    </p>
                    {email && email.value && email.value.address && <p>
                        We will use your email address {email.value.address} and this contact information to connect you with RBN participating agents.
                    </p>}
                </TopMessage>

                <Fields>

                    <Input label="First Name" name="firstName" value={firstName} onChange={updateAccountField} type="text" required={true} ref={firstNameRef}/>
                    <Input label="Last Name" name="lastName" value={lastName} onChange={updateAccountField} type="text" required={true}/>
                    <AutoCompleteLocation label="City/Zip" placeholder="Where would you like to buy? (City or Zip Code)" name="location" onSelect={onSelectLocation} defaultValue={defaultLocation}/>

                    <Select label="Select a purchase time frame" name="timeframe" required value={fields.timeframe} onChange={updateField}>
                        {TIMES.map(time => <option key={time}>{time}</option>)}
                    </Select>
                    <Select label="Select a purchase price range" name="price" required options={PRICE_RANGES} value={fields.price} onChange={updateField}>
                        {PRICE_RANGES.map(price => <option key={price}>{price}</option>)}
                    </Select>

                    <Input
                        type="tel"
                        onChange={(e:any) => {setPhone(e.target.value)}}
                        label={`Enter your phone number`}
                        value={_phone}
                        required={true}
                    />

                    <CheckboxWrapper>
                        <label><input type="checkbox" onChange={(e:any) => setSell(e.target.checked)} checked={sell}/> I also have a home to sell</label>
                    </CheckboxWrapper>
                    <CheckboxWrapper>
                        <label><input type="checkbox" onChange={(e:any) => setHaveAmex(e.target.checked)} checked={haveAmex}/> I have an existing American Express<sup>&reg;</sup> card&nbsp;&nbsp;<Hover title={`U.S. American Express Card Members that have a Card enrolled in the Membership Rewards® program are considered eligible for RBN Rewards ("Eligible Card Members"). If you do not currently have an eligible card, points can be banked in the RBN Virtual Wallet.`} color={theme.accent}/></label>
                    </CheckboxWrapper>

                    {sell && <>
                        <ExistingHomeAddress label="Existing Home Address" name="address" required value={sellFields.address} onChange={updateSellField}/>
                        <LocationContainer>
                            <Input label="City" name="city" placeholder="City" required value={sellFields.city} onChange={updateSellField}/>
                            <Select label="State" name="state" required value={sellFields.state} onChange={updateSellField}>
                                <option key="unselected" value="" disabled={true}>-- Select State --</option>
                                {Object.keys(STATES).map(short => <option key={short} value={short}>{STATES[short]}</option>)}
                            </Select>
                            <Input
                                label="ZIP"
                                name="zip"
                                placeholder="ZIP"
                                required value={sellFields.zip}
                                onChange={updateSellField}
                                type="numeric"
                                minLength={5}
                                maxLength={5}
                            />
                        </LocationContainer>

                    </>}
                </Fields>
                <Buttons>
                    <Button variation="light-blue" disabled={!valid} onClick={onContinue}>Continue</Button>
                </Buttons>
            </Content>
        </Step>
    )
};

export default PurchaseProfile;

export const PRICE_RANGES = [
    'Under $400,000',
    '$400,000 - $700,000',
    '$700,000 - $1,000,000',
    '$1,000,000 +'
];

export const TIMES = [
    'Just Getting Started',
    'Actively Looking',
    'Ready to Move'
];
