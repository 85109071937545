import React, {useEffect} from 'react';
import Page, {Props} from './Page';
import Spinner from './Spinner';

import {useRedirectToLogin} from "../config";
import useViewer from "../lib/use-viewer";

export enum AccountType {
    Any,
    Consumer,
    Provider,
    None
}

interface AuthenticatedPageProps extends Props {
    accountType: AccountType
}

const AuthenticatedPage = (props:AuthenticatedPageProps) => {
    const {loading, viewer, error} = useViewer();
    const redirectToLogin = useRedirectToLogin();

    const {accountType} = props;

    useEffect(() => {
        if (loading)
            return;

        if (error || !viewer ||
            (accountType === AccountType.Consumer && !viewer.consumer) ||
            (accountType === AccountType.Provider && !viewer.provider)
        ) {
            console.log(`redirecting to login`, {loading, viewer, error, accountType});
            redirectToLogin();
        }
    }, [loading, viewer, error, accountType, redirectToLogin]);

    let showSpinner = false;

    if (typeof window === 'undefined' || loading) {
        // not ready yet so show a spinner
        showSpinner = true;
    }
    else if (error || !viewer) {
        // not authenticated so redirect
        showSpinner = true;
    }
    else if (viewer) {
        if (
            (accountType === AccountType.Consumer && !viewer.consumer) ||
            (accountType === AccountType.Provider && !viewer.provider)
        ) {
            showSpinner = true;
        }
    }

    if (showSpinner)
        return <Page key="spinner" fullWidth={true}><Spinner/></Page>;
    else
        return <Page key="authenticated-page" {...props}/>;
};

export default AuthenticatedPage;