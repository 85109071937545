import createTrack from '../../../../lib/create-track';
import {useConsumerViewer} from "../../../../lib/use-viewer";

import VerifyEmail from "./VerifyEmail";
import VerifyPhone from "./VerifyPhone";
import PurchaseProfile from "./PurchaseProfile";
import useRedirect from "../../../../lib/use-redirect";
import SelectAnAgent from "./SelectAnAgent";
import /*ConfirmAgent,*/ {StateProps} from './ConfirmAgent';
import Review from './Review';
import InviteAnAgent from "./InviteAnAgent";
import {useMutation, useQuery} from "@apollo/client";
import {AreaCoverage, AreaCoverageVariables} from "../../../../gql/types/AreaCoverage";
import AreaCoverageQuery from '../../../../gql/AreaCoverage';
import {useLocation} from "react-router";
import sessionStorage from '../../../../lib/session-storage';
import {UpdateAccount, UpdateAccountVariables} from "../../../../gql/types/UpdateAccount";
import UpdateAccountQuery from "../../../../gql/UpdateAccount";
import {ONBOARDED_BY_KEY} from "../../../../lib/check-lander-query-string-params";
import {useEffect} from "react";

export const INVITE_AN_AGENT_KEY = 'inviteAgent';

export enum VerificationMethod {
    None = 0,
    Email = 1,
    Phone = 2
}
export const VERIFICATION_METHOD_KEY = 'verification'

export const getVerificationMethod = () => Number(sessionStorage.getItem(VERIFICATION_METHOD_KEY) || VerificationMethod.None);

const Setup = createTrack('/dashboard/setup', [

    {path: 'purchase-profile', component: PurchaseProfile},
    {path: 'verify-email', component: VerifyEmail},
    {path: 'verify-phone', component: VerifyPhone},
    {path: 'select-an-agent', component: SelectAnAgent},
//    {path: 'confirm-agent', component: ConfirmAgent},
    {path: 'invite-an-agent', component: InviteAnAgent},
    {path: 'review', component: Review}

], () => {
    const {state} = useLocation<StateProps>();
    useRedirect('If you leave now your profile will be incomplete.', /^(?:\/dashboard\/setup)|(?:\/p\/)/);

    const {viewer} = useConsumerViewer();

    const [updateAccount] = useMutation<UpdateAccount, UpdateAccountVariables>(UpdateAccountQuery);

    const onboardedBy = sessionStorage.getItem(ONBOARDED_BY_KEY);

    useEffect(() => {
        if (onboardedBy && viewer && viewer.nodeId) {
            updateAccount({
                variables: {
                    input: {
                        nodeId: viewer.nodeId,
                        patch: {
                            onboardedBy
                        }
                    }
                }
            }).then(() => {
                sessionStorage.removeItem(ONBOARDED_BY_KEY);
            });
        }
    }, [viewer, onboardedBy, updateAccount]);

    const consumer = viewer && viewer.consumer;

    const openInquiries = (
        consumer &&
        consumer.inquiries &&
        consumer.inquiries.nodes &&
        consumer.inquiries.nodes.filter(inquiry => inquiry && !(inquiry.leads && inquiry.leads.nodes && inquiry.leads.nodes.length))
    ) || [];

    const inquiry = openInquiries.length > 0 && openInquiries[0];

    const {data:areaCoverageResponse} = useQuery<AreaCoverage, AreaCoverageVariables>(AreaCoverageQuery, {
        variables: {
            input: (inquiry && inquiry.details && inquiry.details.buying && inquiry.details.buying.locationId) || ''
        }
    });

    let inviteAnAgent = sessionStorage.getItem(INVITE_AN_AGENT_KEY);

    const agentCount = areaCoverageResponse && areaCoverageResponse.areaCoverage &&  areaCoverageResponse.areaCoverage.agentCount;

    if (agentCount === 0 && !inviteAnAgent) {
        //console.log(`setting ${INVITE_AN_AGENT_KEY}, ${inviteAnAgent}, ${agentCount}`);
        inviteAnAgent = '{}';
        sessionStorage.setItem(INVITE_AN_AGENT_KEY, inviteAnAgent);
    }
    else if (agentCount && inviteAnAgent) {
        //console.log(`clearing ${INVITE_AN_AGENT_KEY}, ${inviteAnAgent}, ${agentCount}`);
        inviteAnAgent = null;
        sessionStorage.removeItem(INVITE_AN_AGENT_KEY);
    }

    const verificationMethod:VerificationMethod = getVerificationMethod();

    const email = viewer && viewer.contacts.nodes.find(contact => !!(contact && contact.type === 'email'));
    const emailVerified = !!(email && email.verifiedAt);
    const phone = viewer && viewer.contacts.nodes.find(contact => !!(contact && contact.type === 'phone'));
    const phoneVerified = !!(phone && phone.verifiedAt);

    const purchaseProfileValid = !!(inquiry && inquiry.details && inquiry.details.buying);

    const selectAnAgentValid = !!((inquiry && inquiry.details && inquiry.details.selectedAgents && inquiry.details.selectedAgents.length) || (state && state.agentId));

//    const confirmAgentValid = !!(inquiry && inquiry.details && inquiry.details.selectedAgent);

    const inviteAnAgentValid = !!(inviteAnAgent && Object.keys(JSON.parse(inviteAnAgent)).length > 0);

    return {
        'verify-email': {disabled:(!(verificationMethod & VerificationMethod.Phone)), valid:emailVerified},
        'verify-phone': {disabled:(!(verificationMethod & VerificationMethod.Email)), valid:phoneVerified},
        'purchase-profile': {valid:purchaseProfileValid},
        'select-an-agent': {disabled:!!inviteAnAgent, valid:selectAnAgentValid},
//        'confirm-agent': {disabled:!!inviteAnAgent, valid:confirmAgentValid},
        'invite-an-agent': {disabled:true || !inviteAnAgent, valid:inviteAnAgentValid},
        'review': {}
    };
});

export default Setup;