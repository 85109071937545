import {useLazyQuery, useMutation, useQuery} from "@apollo/client";

import {ProviderByIdQuery, ProviderBySlugQuery, ProviderQuery, UpdateProviderMutation} from '../gql/Provider';
import {Provider, Provider_provider, ProviderVariables} from "../gql/types/Provider";
import {ProviderById, ProviderByIdVariables} from "../gql/types/ProviderById";
import {ProviderBySlug, ProviderBySlugVariables} from "../gql/types/ProviderBySlug";
import {UpdateProvider, UpdateProviderVariables} from "../gql/types/UpdateProvider";
import {ProviderViewer_viewer_provider} from "../gql/types/ProviderViewer";

export function useProvider(nodeId:string) {
    const {data, loading, error} = useQuery<Provider, ProviderVariables>(ProviderQuery, {
        variables: {
            nodeId
        }
    });

    const provider = data && data.provider;

    return {loading, provider, error};
}

export function useProviderById(id:string) {
    const [findProviders, {data, loading, error}] = useLazyQuery<ProviderById, ProviderByIdVariables>(ProviderByIdQuery);

    if (id && !(data || loading || error)) {
        findProviders({
            variables: {
                id
            }
        });
    }

    const provider = data && data.providerById;

    return {loading, provider, error};
}

export function useProviderBySlug(slug:string) {
    const {data, loading, error} = useQuery<ProviderBySlug, ProviderBySlugVariables>(ProviderBySlugQuery, {
        variables: {
            slug
        }
    });

    const provider = data && data.providerBySlug;

    return {loading, provider, error};
}

export function useUpdateProvider() {
    const [updateProviderMutation] = useMutation<UpdateProvider, UpdateProviderVariables>(UpdateProviderMutation);

    return (nodeId:string, patch:any) => updateProviderMutation({
        variables: {
            input: {
                nodeId,
                patch
            }
        }
    });
}

export function getAgentStats(agent: Provider_provider|ProviderViewer_viewer_provider|null|undefined) {
    if (!agent)
        return {};

    const transactionCount = agent.transactionHistory.length;
    const years = new Date().getFullYear() - Number(agent.info.licensedSince);
    const average = Math.round(agent.transactionHistory.reduce((total:number, {soldPrice:price}:any) => total + price, 0) / transactionCount) || 0;
    const averagePrice = (average > 1000000) ? `$${Number(average/1000000).toFixed(2)}M` : `$${Math.round(average/1000)}K`;

    return {transactionCount, years, averagePrice};
}
