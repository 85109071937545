import {apolloClient} from "./apollo";
import queryString from 'query-string';

import useRedirect from "../lib/use-redirect";
import {Login as LoginResult, LoginVariables} from "../gql/types/Login";
import LOGIN from "../gql/Login";

import {RegisterConsumer as RegisterConsumerResult, RegisterConsumerVariables} from "../gql/types/RegisterConsumer";
import REGISTER_CONSUMER from '../gql/RegisterConsumer';

import {RegisterRealEstateAgent as RegisterRealEstateAgentResult, RegisterRealEstateAgentVariables} from "../gql/types/RegisterRealEstateAgent";
import REGISTER_REAL_ESTATE_AGENT from '../gql/RegisterRealEstateAgent';

import {FormEvent, SyntheticEvent, useCallback, useEffect, useState} from "react";
import useFields from "../lib/use-fields";
import {useLazyQuery, useMutation} from "@apollo/client";
import {AreaCoverage as AreaCoverageResult, AreaCoverageVariables} from "../gql/types/AreaCoverage";
import AREA_COVERAGE from '../gql/AreaCoverage';
import {JoinBoxType, getBoxConfig} from "../components/JoinBox";
import {LocationOption} from '../components/form/AutoCompleteLocation';
import {SetPassword as SetPasswordResult, SetPasswordVariables} from "../gql/types/SetPassword";
import SET_PASSWORD from '../gql/SetPassword';
import {ResetPassword as ResetPasswordResult, ResetPasswordVariables} from "../gql/types/ResetPassword";
import RESET_PASSWORD from '../gql/ResetPassword';
import sessionStorage from '../lib/session-storage';
import localStorage from '../lib/local-storage';
import {INVITE_AN_AGENT_KEY} from "../pages/Dashboard/Consumer/Setup/Setup";

export const JWT_KEY = 'jwt';

let lastPushedUserId:string|null = null;

function setUserId(jwt:string|null) {
    if (!w.dataLayer)
        return;

    if (!jwt) {
        if (lastPushedUserId) {
            w.dataLayer.push({userId: null, event: 'authentication'});
            lastPushedUserId = null;
        }

        return;
    }

    const [, b64encoded,] = jwt.split('.');

    const {aid: userId}: any = JSON.parse(atob(b64encoded));

    if (userId !== lastPushedUserId) {
        w.dataLayer.push({userId, event: 'authentication'});
        lastPushedUserId = userId;
    }
}

export const saveJWT = (jwt:string) => {
    localStorage.setItem(JWT_KEY, jwt);

    setUserId(jwt);

    return apolloClient.resetStore();
};

const w:any = window;

export const getJWT = () => {
    const jwt:string|null = localStorage.getItem(JWT_KEY);

    setUserId(jwt);

    return jwt;
};

export const clearJWT = () => {
    setUserId(null);

    return localStorage.removeItem(JWT_KEY);
};

export function useClearStorage(): () => Promise<void> {
    return useCallback(() => {
        localStorage.clear();
        sessionStorage.clear();

        setUserId(null)

        return apolloClient.resetStore().then(() => {});
    }, []);
}

export function useLogout(): (event?:SyntheticEvent) => void {
    const redirect = useRedirect();
    const clearStorage = useClearStorage();

    return (event?:SyntheticEvent) => {
        event && event.preventDefault();

        clearStorage()
            .then(() => redirect('/login'));
    };
}

export const LOGIN_REDIRECT_KEY = 'login-redirect';

export function useRedirectToLogin(): () => void {
    const redirect = useRedirect();

    return useCallback(() => {
        const path = redirect('/login');

        // save the requested path so we can return after login
        sessionStorage.setItem(LOGIN_REDIRECT_KEY, path);
    }, [redirect]);
}

export function useLoginRedirect(): () => void {
    const redirect = useRedirect();

    return () => {
        const path = sessionStorage.getItem(LOGIN_REDIRECT_KEY);

        if (path)
            sessionStorage.removeItem(LOGIN_REDIRECT_KEY);

        redirect(path || '/dashboard');
    }
}

interface LoginFields {
    email: string,
    password: string
}

interface UseLogin {
    login: (event?:FormEvent) => Promise<void>,
    fields: LoginFields,
    updateField: (value:string) => void,
    error?: string
}

export function useLogin(): UseLogin {
    const {fields, updateField} = useFields(['email', 'password']);
    const [loginMutation] = useMutation<LoginResult, LoginVariables>(LOGIN, {variables: {input: fields}});
    const loginRedirect = useLoginRedirect();
    const [error, setError] = useState('');

    const login = (event?:FormEvent) => {
        event && event.preventDefault();

        return loginMutation()
            .then(result => {
                if (!result)
                    throw new Error('Server error, please try again later.');

                return result;
            })
            .then(({data}) => data && data.login && data.login.jwtToken)
            .then((jwt) => {
                if (!jwt)
                    throw new Error('Invalid email or password.');

                return saveJWT(jwt);
            })
            .then(loginRedirect)
            .catch((error:Error) => {
                if (setError)
                    setError(error.message);
                else
                    throw error;
            });
    };

    return {login, fields, updateField, error};
}

interface ResetPasswordFields {
    email: string
}

interface ResetPassword {
    resetPassword: (event?:FormEvent) => Promise<void>,
    fields: ResetPasswordFields,
    updateField: (value:string) => void,
    error?: string
}

export function useResetPassword(): ResetPassword {
    const {fields, updateField} = useFields(['email']);
    const [resetPasswordMutation] = useMutation<ResetPasswordResult, ResetPasswordVariables>(RESET_PASSWORD, {variables: {input: fields}});
    const [error, setError] = useState('');

    const resetPassword = (event?:FormEvent) => {
        event && event.preventDefault();

        return resetPasswordMutation()
            .then(result => {
                if (!result)
                    throw new Error('Server error, please try again later.');
            })
            .catch((error:Error) => {
                if (setError)
                    setError(error.message);
                else
                    throw error;
            });
    };

    return {resetPassword, fields, updateField, error};
}

interface SetPasswordFields {
    password: string,
    confirmPassword: string
}

interface UseSetPassword {
    setPassword: (event?:FormEvent) => Promise<void>;
    fields: SetPasswordFields;
    updateField: (value:string) => void;
    loading?: boolean;
    error?: string;
}

export function useSetPassword(): UseSetPassword {
    const {fields, updateField} = useFields(['password', 'confirmPassword']);
    const [setPasswordMutation, {loading}] = useMutation<SetPasswordResult, SetPasswordVariables>(SET_PASSWORD, {
        variables: {
            input: {
                password: fields.password
            }
        }
    });
    const [error, setError] = useState('');

    const setPassword = (event?:FormEvent) => {
        event && event.preventDefault();

        if (fields.password.length < 6) {
            setError('Password must be at least 6 characters');
            return Promise.reject();
        }

        if (!(/[a-zA-Z]/.test(fields.password) && /\d|\W/.test(fields.password))) {
            setError('Password must include at least one letter and one number or symbol');
            return Promise.reject();
        }

        if (fields.password !== fields.confirmPassword) {
            setError('Password and Confirm Password must be the same');
            return Promise.reject();
        }

        return setPasswordMutation()
            .then(result => {
                if (!result)
                    throw new Error('Server error, please try again later.');
            })
            .catch((error:Error) => {
                if (setError)
                    setError(error.message);
                else
                    throw error;
            });
    };

    return {setPassword, fields, updateField, loading, error};
}

export interface RegisterFields {
    firstName: string,
    lastName: string,
    email?: string,
    phone?: string,
    referrer?: string,
    campaign?: any,
    serviceAreaZipCode?: string|null
}

export interface UseRegister {
    register: (event?:FormEvent) => Promise<void>,
    fields: RegisterFields,
    updateField: (name:any, value?:any) => void,
    updateFields?: (map: any) => void,
    error?: string
}

interface RegisterConsumerFields extends RegisterFields {
}

interface UseRegisterConsumer extends UseRegister {
    fields: RegisterConsumerFields
}

export function stripEmptyFields<T extends RegisterFields>(fields:T) {
    const map = {...fields};

    if (!map.referrer)
        delete map.referrer;

    if (!map.phone)
        delete map.phone;

    return map;
}

export function addCampaign(defaultValues:any) {
    const CAMPAIGN_INFO = 'campaign';

    let campaign = JSON.parse(sessionStorage.getItem(CAMPAIGN_INFO) || 'null');

    if (!campaign) {
        const params = queryString.parse(document.location.search);

        campaign = Object.assign({
            page: document.location.pathname,
            referrer: document.referrer
        }, defaultValues && defaultValues.campaign, params);

        sessionStorage.setItem(CAMPAIGN_INFO, JSON.stringify(campaign));
    }

    return Object.assign({campaign}, defaultValues);
}

export function useRegisterConsumer(defaultValues?:RegisterConsumerFields, onRegistered?:() => void): UseRegisterConsumer {
    const {fields, updateField, updateFields} = useFields(['firstName', 'lastName', 'email', 'phone', 'referrer'], addCampaign(defaultValues));
    const variables = {input:stripEmptyFields(fields)};
    const [registerConsumerMutation] = useMutation<RegisterConsumerResult, RegisterConsumerVariables>(REGISTER_CONSUMER, {variables});
    const loginRedirect = useLoginRedirect();
    const [error, setError] = useState('');
    const [areaCoverage, {called, loading, data, error:areaCoverageError}] = useLazyQuery<AreaCoverageResult, AreaCoverageVariables>(AREA_COVERAGE);

    const next = onRegistered || loginRedirect;

    useEffect(() => {
        if (!called || loading)
            return;

        if (data) {
            if (!(data.areaCoverage && data.areaCoverage.agentCount)) // no agents
                sessionStorage.setItem(INVITE_AN_AGENT_KEY, '{}');

            next();
        }
        else {
            console.error(areaCoverageError);
            next();
        }
    }, [called, loading, areaCoverageError, data, next]);

    const register = (event?:FormEvent) => {
        event && event.preventDefault();

        const form:any = event && event.target;

        const confirmEmail = form['confirmEmail'];

        if (confirmEmail !== undefined && confirmEmail.value !== fields['email']) {
            setError('Email Address and Confirm Email Address must match');

            return Promise.resolve();
        }

        const sessionKey = getBoxConfig(JoinBoxType.MEMBER).sessionKey;
        const location:LocationOption|null = JSON.parse(sessionStorage.getItem(sessionKey) || 'null');

        return registerConsumerMutation()
            .then(result => {
                if (!result)
                    throw new Error('Server error, please try again later.');

                return result;
            })
            .then(({data}) => {
                return data && data.registerConsumer && data.registerConsumer.jwtToken;
            })
            .catch(errors => {
                console.error({errors});

                if (errors.message) {
                    if (errors.message.match(/duplicate key.*email/))
                        throw new Error(`${fields.email} is already registered`);

                    throw errors;
                }

                throw new Error('Error processing request');
            })
            .then((jwt) => {
                if (!jwt)
                    throw new Error('Something went horribly wrong!');

                return saveJWT(jwt);
            })
            .then(async () => {
                if (location && location.id) {
                    await areaCoverage({variables: {input: location.id}});
                }
                else {
                    await next();
                }
            })
            .catch(error => {
                if (setError)
                    setError(error);
                else
                    throw error;
            });
    };

    return {register, fields, updateField, updateFields, error};
}

interface RegisterRealEstateAgentFields extends RegisterFields {
}

interface UseRegisterRealEstateAgent extends UseRegister {
    fields: RegisterRealEstateAgentFields,
}

export function useRegisterRealEstateAgent(defaultValues?:RegisterRealEstateAgentFields): UseRegisterRealEstateAgent {
    const {fields, updateField, updateFields} = useFields(['firstName', 'lastName', 'email', 'phone', 'referrer', 'serviceAreaZipCode'], addCampaign(defaultValues));
    const [registerProviderMutation] = useMutation<RegisterRealEstateAgentResult, RegisterRealEstateAgentVariables>(REGISTER_REAL_ESTATE_AGENT, {variables: {input: stripEmptyFields(fields)}});
    const loginRedirect = useLoginRedirect();
    const [error, setError] = useState('');

    const register = (event?:FormEvent) => {
        event && event.preventDefault();

        const form:any = event && event.target;

        const confirmEmail = form['confirmEmail'];

        if (confirmEmail !== undefined && confirmEmail.value !== fields['email']) {
            setError('Email Address and Confirm Email Address must match');

            return Promise.resolve();
        }

        return registerProviderMutation()
            .then(result => {
                if (!result)
                    throw new Error('Server error, please try again later.');

                return result;
            })
            .then(({data}) => {
                return data && data.registerRealEstateAgent && data.registerRealEstateAgent.jwtToken;
            })
            .catch(errors => {
                console.error({errors});

                if (errors.message) {
                    if (errors.message.match(/duplicate key.*email/))
                        throw new Error(`${fields.email} is already registered`);

                    throw errors;
                }

                throw new Error('Error processing request');
            })
            .then((jwt) => {
                if (!jwt)
                    throw new Error('Something went horribly wrong!');

                return saveJWT(jwt);
            })
            .then(loginRedirect)
            .catch(error => {
                if (setError)
                    setError(error.message);
                else
                    throw error;
            });
    };

    return {register, fields, updateField, updateFields, error};
}