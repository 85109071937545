import React from "react";
import {ApolloProvider} from "@apollo/client";
import {HelmetProvider} from "react-helmet-async";
import {ThemeProvider} from "styled-components";
import ScrollToTop from "react-router-scroll-top";

import {apolloClient} from "./config";
import theme from "./styles";
import Routes from "./Routes";

function App() {
    return (
        <ApolloProvider client={apolloClient}>
            <HelmetProvider>
                <ThemeProvider theme={theme}>
                    <ScrollToTop>
                        <Routes/>
                    </ScrollToTop>
                </ThemeProvider>
            </HelmetProvider>
        </ApolloProvider>
    );
}

export default App;