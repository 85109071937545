import React, {FormEvent} from 'react';
import styled from "styled-components";
//import members from "../pages/JoinNow/members.jpg";
//import agents from "../pages/JoinNow/agents.jpg";
import {clearJWT, RegisterFields, useRegisterConsumer, useRegisterRealEstateAgent} from "../config";
import {Button, Input} from "./form";
import Error from "./Error";
import AutoCompleteLocation, {LocationOption} from "./form/AutoCompleteLocation";
import {Link} from "react-router-dom";
import {useInviteAgent} from "../lib/use-invite-agent";

const Cell = styled.div`
  background-color: ${({theme:{accent}}) => accent};
  display: flex;
  flex-direction: column;
  padding: 1rem;
  max-width: 400px;
  
  @media (max-width: 1080px) {
  }
  
  @media (max-width: 800px) {
    width: 100%;
  }
  
  @media (max-width: 480px) {
    width: 100%;
    max-width: 100%;
  }
`;

export enum JoinBoxType {
    MEMBER,
    AGENT,
    INVITE_AGENT
}

interface Fields {
    referrer: string,
    firstName: string,
    lastName: string,
    email: string
}

interface BoxProps {
    type: JoinBoxType,
    short?: boolean,
    button?: string,
    heading?: any,
    defaultFields?: Fields,
    noHeader?: boolean,
    className?: string
}

const SESSION_KEY_LOCATION = 'BoxLocation';

export function getBoxConfig(type:JoinBoxType) {
    switch(type) {
        case JoinBoxType.MEMBER:
            return {
                title: '',
//                image: members,
                locationPlaceholder: 'Where would you like to buy? (City or Zip Code)',
                button: 'Join Now',
                useRegister: (defaultValues?:RegisterFields) => useRegisterConsumer(defaultValues),
                sessionKey: `${SESSION_KEY_LOCATION}.Member`,
            };
        case JoinBoxType.AGENT:
            return {
                title: 'Agents',
//                image: agents,
                locationPlaceholder: 'What is your primary service area? (City or Zip Code)',
                button: 'Apply Now',
                useRegister: (defaultValues?:RegisterFields) => useRegisterRealEstateAgent(defaultValues),
                sessionKey: `${SESSION_KEY_LOCATION}.Agent`
            };
        case JoinBoxType.INVITE_AGENT:
            return {
                title: 'Invite an Agent',
//                image: agents,
                locationPlaceholder: '',
                button: 'Invite Now',
                useRegister: (defaultValues?:RegisterFields) => useInviteAgent(defaultValues),
                sessionKey: `${SESSION_KEY_LOCATION}.Agent`
            };
    }
}

const Email = styled(Input).attrs({
    placeholder: 'Email Address',
    name: 'email',
    required: true
})``;

export const Agree = styled.div`
  color: #fff;
  font-size: 70%;
  opacity: .75;
  
  margin-top: 1.5rem;
  
  a {
    color: #fff;
  }
  
  @media (max-width: 480px) {
    font-size: 2.85vw;
  }
`;

export const JoinBox = ({
    type,
    defaultFields,
    noHeader,
    short,
    button: buttonOverride,
    heading,
    className
}:BoxProps) => {
    const {title, locationPlaceholder, button, useRegister} = getBoxConfig(type);
    const {register, fields: {firstName, lastName, email, serviceAreaZipCode}, updateField, updateFields, error} = useRegister(defaultFields);

    const onSelectLocation = (location:LocationOption|null) => {
        if (typeof updateFields === 'function')
            updateFields({serviceAreaZipCode: (location && location.id) || null});
    };

    const valid = (short && email) || (firstName && lastName && email && (!locationPlaceholder || serviceAreaZipCode));

    const submitHandler = (event?:FormEvent) => {
        if (!valid)
            return;

        clearJWT();

        register(event).then();
    };

    return (
        <Cell className={className}>
            {heading}
            {!noHeader && !heading && <Header>
                {title && <div>{title}</div>}
            </Header>}
            <Form
                onSubmit={submitHandler}
                className={type === JoinBoxType.INVITE_AGENT ? 'refer-agent' : ''}
            >
                {!short && <Input placeholder="First Name" name="firstName" value={firstName} onChange={updateField} type="text" required={true}/>}
                {!short && <Input placeholder="Last Name" name="lastName" value={lastName} onChange={updateField} type="text" required={true}/>}
                <Email value={email} onChange={updateField} type="email"/>
                {!short && locationPlaceholder && false &&
                    <AutoCompleteLocation placeholder={locationPlaceholder} name="location" onSelect={onSelectLocation} selectOnBlur={true}/>
                }
                <Error error={error}/>
                <Button variation="green" disabled={!valid}>{buttonOverride || button}</Button>
                {short && (
                    <Agree>
                        By joining, I agree to RBN's <Link to="/agreements/terms-and-conditions">Terms &amp; Conditions</Link> and <Link to="/agreements/privacy-policy">Privacy Policy</Link>.
                    </Agree>
                )}
            </Form>
        </Cell>
    )
};

const Header = styled.div`
  position: relative;
  
  & > :last-child {
    color: #fff;
    text-align: center;
    font-size: 28px;
    padding-bottom: .5rem;
  }
    
  img {
    width: 100%;
    height: auto;
  }
  
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  
  label {
    margin: 0;
  }
  
  ${Email}, label:nth-child(4) {
    grid-column: 1/3;
  }
  
  ${Email}:first-child {
    margin-top: 1rem;
  }

  button, & > div {
    grid-column: 1/3;
  }
  
  input {
    margin: 0;
  }
  
  button {
    margin-top: 1rem;
  }
`;
