import gql from "graphql-tag";

export const fragments = {
    avatar: gql`
        fragment ViewerAvatar on Account {
            avatar {
                nodeId
                url
                meta
            }
        }
    `,

    contacts: gql`
        fragment ViewerContacts on Account {
            contacts {
                nodes {
                    id
                    nodeId
                    type
                    value
                    verificationCodeSent
                    verifiedAt
                    verificationAttempts
                }
            }
        }
    `,

    consumer: gql`
        fragment ViewerConsumer on Account {
            consumer {
                nodeId
                id
                vendors
                
                inquiries {
                    nodes {
                        id
                        nodeId
                        details
                        detailsConfirmed
                        matches
                        providers
                    
                        leads {
                            nodes {
                                nodeId
                                status
                            }
                        }
                    }
                }
                
                leads {
                    nodes {
                        nodeId
                        status
                        info
                        transaction
                        provider {
                            nodeId
                            id
                            type
                            name
                            phone
                            email
                            info
                            image {
                                url
                            }
                        }
                    }
                }
                
                appointment
                
                engagebay
                
                dealAgents {
                    nodes {
                        dealUniqueId
                        selected
                        provider {
                            nodeId
                            id
                            account {
                                nodeId
                                id
                                firstName
                                lastName
                            }
                            email
                            phone
                            info
                            image {
                                url
                            }
                        }
                    }
                }
            }
        }
    `,

    provider: gql`
        fragment ViewerProvider on Account {
            docusignEnvelopeRecipients {
                nodes {
                    status
                }
            }
            
            provider {
                id
                nodeId
                name
                title
                slug
                phone
                email
                info
                setupComplete
                approvalRequested
                approved
                public
                docusignStatus
                
                image {
                    url
                }
                
                leads {
                    nodes {
                        id
                        nodeId
                        status
                        transaction
                        ebDealUniqueId
                        inquiry {
                            id
                            nodeId
                            details
                            consumer {
                                id
                                nodeId
                                account {
                                    id
                                    nodeId
                                    firstName
                                    lastName
                                    
                                    contacts {
                                        nodes {
                                            type
                                            value
                                        }
                                    }
                                }
                                engagebay
                            }
                        }
                        info
                        history
                        createdAt
                    }
                }
                
                transactionHistory
                geoJson
                areaUpdated
            }
        }
    `,

    invitations: gql`
        fragment Invitations on Account {
            invitations(orderBy: CREATED_AT_DESC) {
                nodes {
                    nodeId
                    firstName
                    lastName
                    email
                    phone
                    createdAt
                }
            }
        }
    `,

    payments: gql`
        fragment Payments on Account {
            payments(orderBy: CREATED_AT_DESC) {
                nodes {
                    nodeId
                    type
                    amount
                    approved
                    message
                    createdAt
                }
            }
        }
    `
};

export default gql`
    query Viewer {
      viewer {
        nodeId
        id
        firstName
        lastName
        passwordEmpty
        onboardedBy
        
        ...ViewerAvatar
        ...ViewerContacts
        consumer {
                nodeId
        }
        provider {
                nodeId
        }
      }
    }
    
    ${fragments.avatar}
    ${fragments.contacts}
`;

export const ConsumerViewerQuery = gql`
    query ConsumerViewer {
      viewer {
        nodeId
        id
        firstName
        lastName
        passwordEmpty
        onboardedBy

        provider {
                nodeId
        }
        
        ...ViewerAvatar
        ...ViewerContacts
        ...ViewerConsumer
        ...Invitations
      }
    }
    
    ${fragments.avatar}
    ${fragments.contacts}
    ${fragments.consumer}
    ${fragments.invitations}
`;

export const ProviderViewerQuery = gql`
    query ProviderViewer {
      viewer {
        nodeId
        id
        firstName
        lastName
        passwordEmpty
        onboardedBy
        
        consumer {
                nodeId
        }

        ...ViewerAvatar
        ...ViewerContacts
        ...ViewerProvider
      }
    }
    
    ${fragments.avatar}
    ${fragments.contacts}
    ${fragments.provider}
`;