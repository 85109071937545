/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum LeadStatus {
  CLOSED = "CLOSED",
  IN_ESCROW = "IN_ESCROW",
  SHOPPING = "SHOPPING",
  WAITING_TO_CONNECT = "WAITING_TO_CONNECT",
}

export enum LogLevel {
  DEBUG = "DEBUG",
  ERROR = "ERROR",
  INFO = "INFO",
  VERBOSE = "VERBOSE",
  WARN = "WARN",
}

export enum PaymentType {
  MEMBERSHIP = "MEMBERSHIP",
}

export enum ProviderType {
  REAL_ESTATE_AGENT = "REAL_ESTATE_AGENT",
}

/**
 * Represents an update to a `Account`. Fields that are set will be updated.
 */
export interface AccountPatch {
  firstName?: string | null;
  lastName?: string | null;
  referredBy?: any | null;
  avatarId?: any | null;
  campaign?: any | null;
  onboardedBy?: any | null;
  suppressMessages?: boolean | null;
}

/**
 * Represents an update to a `Consumer`. Fields that are set will be updated.
 */
export interface ConsumerPatch {
  vendors?: any | null;
  engagebay?: any | null;
  providersInArea?: string | null;
}

/**
 * An input for mutations affecting `Contact`
 */
export interface ContactInput {
  type: string;
  value: any;
  unsubscribedAt?: any | null;
  verificationCodeSent?: any | null;
}

/**
 * All input for the `contactUs` mutation.
 */
export interface ContactUsInput {
  clientMutationId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  message?: string | null;
}

/**
 * All input for the create `Contact` mutation.
 */
export interface CreateContactInput {
  clientMutationId?: string | null;
  contact: ContactInput;
}

/**
 * All input for the create `Inquiry` mutation.
 */
export interface CreateInquiryInput {
  clientMutationId?: string | null;
  inquiry: InquiryInput;
}

/**
 * All input for the create `Invitation` mutation.
 */
export interface CreateInvitationInput {
  clientMutationId?: string | null;
  invitation: InvitationInput;
}

/**
 * All input for the create `Log` mutation.
 */
export interface CreateLogInput {
  clientMutationId?: string | null;
  log: LogInput;
}

/**
 * All input for the create `Message` mutation.
 */
export interface CreateMessageInput {
  clientMutationId?: string | null;
  message: MessageInput;
}

/**
 * All input for the `deleteContact` mutation.
 */
export interface DeleteContactInput {
  clientMutationId?: string | null;
  nodeId: string;
}

/**
 * All input for the `hire` mutation.
 */
export interface HireInput {
  clientMutationId?: string | null;
  providerId?: any | null;
}

/**
 * An input for mutations affecting `Inquiry`
 */
export interface InquiryInput {
  id?: any | null;
  details: any;
  detailsConfirmed?: any | null;
  providers?: any | null;
  flags?: any | null;
  ebDealUniqueId?: string | null;
}

/**
 * Represents an update to a `Inquiry`. Fields that are set will be updated.
 */
export interface InquiryPatch {
  details?: any | null;
  detailsConfirmed?: any | null;
  providers?: any | null;
  flags?: any | null;
  ebDealUniqueId?: string | null;
}

/**
 * An input for mutations affecting `Invitation`
 */
export interface InvitationInput {
  firstName: string;
  lastName: string;
  email: any;
  phone?: string | null;
  info?: any | null;
}

/**
 * Represents an update to a `Lead`. Fields that are set will be updated.
 */
export interface LeadPatch {
  status?: LeadStatus | null;
  info?: any | null;
  history?: any | null;
  transaction?: any | null;
  ebDealUniqueId?: string | null;
}

/**
 * An input for mutations affecting `Log`
 */
export interface LogInput {
  level: LogLevel;
  type: string;
  data: any;
}

/**
 * All input for the `login` mutation.
 */
export interface LoginInput {
  clientMutationId?: string | null;
  email: string;
  password: string;
}

/**
 * All input for the `loginUsingSmsToken` mutation.
 */
export interface LoginUsingSmsTokenInput {
  clientMutationId?: string | null;
  email: string;
  token: string;
}

/**
 * An input for mutations affecting `Message`
 */
export interface MessageInput {
  toId: any;
  message: string;
}

/**
 * Represents an update to a `Provider`. Fields that are set will be updated.
 */
export interface ProviderPatch {
  id?: any | null;
  accountId?: any | null;
  type?: ProviderType | null;
  name?: string | null;
  gender?: string | null;
  title?: string | null;
  slug?: any | null;
  imageId?: any | null;
  phone?: string | null;
  email?: any | null;
  info?: any | null;
  transactionHistory?: any | null;
  approved?: boolean | null;
  public?: boolean | null;
  tier?: number | null;
  setupComplete?: any | null;
  approvalRequested?: any | null;
  minPrice?: number | null;
  maxPrice?: number | null;
  engagebay?: any | null;
  areaUpdated?: any | null;
  status?: any | null;
  areaCenter?: any | null;
}

/**
 * All input for the `registerConsumer` mutation.
 */
export interface RegisterConsumerInput {
  clientMutationId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  referrer?: any | null;
  campaign?: any | null;
}

/**
 * All input for the `registerRealEstateAgent` mutation.
 */
export interface RegisterRealEstateAgentInput {
  clientMutationId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  referrer?: any | null;
  campaign?: any | null;
  serviceAreaZipCode?: string | null;
}

/**
 * All input for the `resetPassword` mutation.
 */
export interface ResetPasswordInput {
  clientMutationId?: string | null;
  email: string;
}

/**
 * All input for the `sendSmsLoginToken` mutation.
 */
export interface SendSmsLoginTokenInput {
  clientMutationId?: string | null;
  email: string;
}

/**
 * All input for the `sendVerificationCode` mutation.
 */
export interface SendVerificationCodeInput {
  clientMutationId?: string | null;
  contactId: any;
}

/**
 * All input for the `setPassword` mutation.
 */
export interface SetPasswordInput {
  clientMutationId?: string | null;
  password: string;
}

/**
 * All input for the `tokenLogin` mutation.
 */
export interface TokenLoginInput {
  clientMutationId?: string | null;
  token: any;
}

/**
 * All input for the `updateAccount` mutation.
 */
export interface UpdateAccountInput {
  clientMutationId?: string | null;
  nodeId: string;
  patch: AccountPatch;
}

/**
 * All input for the `updateConsumer` mutation.
 */
export interface UpdateConsumerInput {
  clientMutationId?: string | null;
  nodeId: string;
  patch: ConsumerPatch;
}

/**
 * All input for the `updateGeoJson` mutation.
 */
export interface UpdateGeoJsonInput {
  clientMutationId?: string | null;
  geoJson?: any | null;
  pid?: any | null;
}

/**
 * All input for the `updateInquiry` mutation.
 */
export interface UpdateInquiryInput {
  clientMutationId?: string | null;
  nodeId: string;
  patch: InquiryPatch;
}

/**
 * All input for the `updateLead` mutation.
 */
export interface UpdateLeadInput {
  clientMutationId?: string | null;
  nodeId: string;
  patch: LeadPatch;
}

/**
 * All input for the `updateProvider` mutation.
 */
export interface UpdateProviderInput {
  clientMutationId?: string | null;
  nodeId: string;
  patch: ProviderPatch;
}

/**
 * All input for the `verifyContact` mutation.
 */
export interface VerifyContactInput {
  clientMutationId?: string | null;
  contactId: any;
  code: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
