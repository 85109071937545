function getVersion() {
    let version = 'local';

    switch (document.location.hostname) {
        case 'dev.joinrbn.com':
            version = 'dev';
            break;
        case 'staging.joinrbn.com':
            version = 'staging';
            break;
        case 'joinrbn.com':
        case 'www.joinrbn.com':
            version = 'production';
            break;
    }

    return version;
}

export const version = getVersion();

if (!['production'].includes(version))
    console.log(`%c stage: ${version}`, 'font-size: 24px; font-weight: bold; color: #090; margin: 1em 0;');
