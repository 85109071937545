import gql from 'graphql-tag';

export default gql`
query FindProviders($type: ProviderType!, $inquiryId: UUID!) {
    findProviders(type: $type, inquiryId: $inquiryId) {
        nodes {
            id
        }
    }
}
`;