import {useLogout} from "../config";

const Logout = () => {
    const logout = useLogout();

    logout();

    return null;
};

export default Logout;