import React, {useCallback, useEffect, useState} from "react";
import {Route, RouteComponentProps} from "react-router";

export interface PageProps extends RouteComponentProps {
    page: {
        path: string,
        previous: (state?: PageState|((state:PageState) => PageState)) => void,
        next: (state?: PageState|((state:PageState) => PageState)) => void,
        state: PageState,
        setState: (state:PageState) => void
    },
    track: {
        basePath: string,
        pageStates: PageStates,
        pages: Array<Page>
    }
}

export interface PageState {
    valid?: boolean,
    disabled?: boolean
}

export interface Page {
    path: string,
    component: (props:PageProps) => any
}

interface PageStates {
    [key: string]: PageState
}

export default function createTrack(basePath:string, pages:Array<Page>, getPageStates:(props:RouteComponentProps) => PageStates) {
    // add trailing slash to base path if it doesn't already have one
    basePath = basePath.endsWith('/') ? basePath : `${basePath}/`;

    const normalizePath = (path:string) => {
        if (path.startsWith(basePath))
            return path;
        else
            return `${basePath}${path.replace(/^\//, '')}`;
    };

    pages = pages.map(page => Object.assign({}, page, {path: normalizePath(page.path)}));

    const getPages = (pageStates:PageStates) => {
        let foundInvalidPage = false;

        return pages
            .filter((page, i) => {
                if (foundInvalidPage)
                    return false;

                const state = pageStates[page.path];

                if (state.disabled)
                    return false;

                foundInvalidPage = !(state && state.valid);

                return true;
            });
    };

    const usePageStates = (props: RouteComponentProps) => {
        const initialStates:PageStates = getPageStates(props);

        const normalizedStates = Object.keys(initialStates).reduce<PageStates>((states, path) => {
            states[normalizePath(path)] = initialStates[path];
            return states;
        }, {});

        const [states, setStates] = useState<PageStates>(normalizedStates);

        const update = Object.keys(normalizedStates).reduce<any|null>((update, path) => {
            if (normalizedStates[path].disabled !== states[path].disabled) {
                if (!update)
                    update = {};

                update[path] = Object.assign({}, states[path], {disabled: normalizedStates[path].disabled});
            }

            return update;
        }, null);

        if (update) {
            //console.log({states, update});
            setStates(Object.assign({}, states, update));
        }

        const setPageState = useCallback((page: Page, state: any) => {
            const oldState = states[page.path];
            const newState = Object.assign({}, oldState, state);

            return setStates(Object.assign({}, states, {
                    [page.path]: newState
                }
            ));
        }, [states]);

        return {
            pageStates: states,
            setPageState
        }
    };

    const pageFromPath = (props: RouteComponentProps, pageStates: PageStates) => {
        const {location: {pathname: path}, history: {replace}} = props;

        const pages = getPages(pageStates);

        let index = pages.findIndex(page => page.path === path);

        if (index < 0)
            index = pages.findIndex(page => !(pageStates[page.path].valid))

        if (index < 0)
            index = 0;

        const page = pages[index];

        if (path !== page.path)
            replace(page.path);

        return {index, page};
    };

    const useShowPage = ({history, location: {pathname}}: RouteComponentProps, pageStates: PageStates) => {
        return useCallback((i: number) => {
            const pages = getPages(pageStates);

            if (i < 0 || pages.length <= i)
                return;

            const page = pages[i];

            if (pathname !== page.path)
                history.push(page.path);
        }, [history, pathname, pageStates]);
    };

    const Track = (trackProps: RouteComponentProps) => {
        const {pageStates, setPageState} = usePageStates(trackProps);
        const {page, index} = pageFromPath(trackProps, pageStates);
        const [jump, setJump] = useState<number|null>(null);
        const showPage = useShowPage(trackProps, pageStates);
        const state = pageStates[page.path];
        const setState = useCallback((state: PageState|((state:PageState) => PageState)) => {
            if (typeof state === 'function')
                return setPageState(page, state(pageStates[page.path]));
            else
                return setPageState(page, state);
            }, [setPageState, page, pageStates]);

        useEffect(() => {
            if (jump) {
                setJump(null);
                showPage(jump);
            }
        }, [jump, showPage]);

        const previous = (state?: PageState|((state:PageState) => PageState)) => {
            if (state)
                setState(state);

            setJump(index - 1);
        };

        const next = (state?: PageState|((state:PageState) => PageState)) => {
            if (state)
                setState(state);

            setJump(index + 1);
        };

        const props = Object.assign({
            page: {
                path: page.path,
                previous,
                next,
                state,
                setState
            },
            track: {
                basePath,
                pageStates,
                pages
            }
        }, trackProps);

        return React.createElement(page.component, props);
    };

    return (
        <Route key={basePath} path={basePath} component={Track}/>
    );
}
