import styled from "styled-components";
import {Buttons} from "../../../../components/form";
import {StyledAgreement} from './Agreement';

const Content = styled.div`
  max-width: 640px;
  margin: 0 auto;
  padding: 2rem 1rem;
  text-align: center;
  
  color: #333;
  
  & > p {
    margin: 0 0 2rem;
  }
  
  & > ${Buttons} {
    justify-content: flex-end;
    margin: 2rem 0 1rem;
  }
  
  ${StyledAgreement} {
    text-align: right;
  }
  
  @media (max-width: 480px) {
    width: 100vw;
  }
  
  ${Buttons} {
    justify-content: flex-end;
  }
`;

export default Content;