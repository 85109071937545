import {Link} from "react-router-dom";
import UnstyledAgreement from "../../../../components/Agreement";
import React from "react";
import styled from "styled-components";

export const StyledAgreement = styled(UnstyledAgreement)`
  a {
    color: inherit;
  }
`;

const Agreement = ({button="continue"}:{button?:string}) => (
    <StyledAgreement>By clicking {button}, I agree to all <Link to="/agreements/terms-and-conditions" target="_blank">Terms</Link>.</StyledAgreement>
);

export default Agreement;