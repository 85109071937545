import React, {FormEvent, useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {useMutation} from "@apollo/client";
import {VerifyContact, VerifyContactVariables} from "../gql/types/VerifyContact";
import VERIFY_CONTACT from "../gql/VerifyContact";
import {Button, Buttons} from "./form";
import useSendVerificationCode from "../lib/use-send-verification-code";
import useViewer from "../lib/use-viewer";

interface DigitProps {
    className?: string,
    value: string,
    onChange: (c:string) => void,
    onPaste: (e:any) => void,
    disabled: boolean,
    onContinue: (e:FormEvent) => void
}

const Digit = styled(({className, value, onChange, onPaste, disabled, onContinue}:DigitProps) => {
    const ref = useRef(null);

    const update = (e:any) => {
        const {target, target:{value}} = e;

        onChange(value);

        if (value.length === 1) {
            const next = target.nextSibling;

            if (next)
                next.focus();
        }
    };

    const onKeyDown = (e:any) => {
        if (e.keyCode === 8 && value === '') {
            const previous = e.target.previousSibling;

            if (previous)
                previous.focus();
        }
        else if (e.keyCode === 13) {
            onContinue(e);
        }
    };

    useEffect(() => {
        const t:any = ref.current;

        if (!t)
            return;

        if (!t.previousSibling)
            t.focus();
    }, [ref]);

    return (
        <input
            type="tel"
            className={className}
            value={value}
            maxLength={1}
            onChange={update}
            onKeyDown={onKeyDown}
            onPaste={onPaste}
            disabled={disabled}
            ref={ref}
        />
    );
})`
    width: 1em;
    font-size: 48px;
    text-align: center;
    text-transform: uppercase;
    
    @media (max-width: 480px) {
    }
`;

const PinEntry = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  justify-content: center;
  grid-column-gap: 2rem;
  margin: 0 0 2rem;
  
  @media (max-width: 480px) {
    grid-column-gap: .5rem;
  }
`;

const Error = styled.div`
  margin: 1rem 0;
  color: #f00;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

const ContactVerificationWrapper = styled.div`
@media (min-width: 481px) {
    display: inline-block;
}

${Button} {
    @media (max-width: 480px) {
        padding: .5rem;
        text-transform: none;
        border-width: 1px;
    }
}
`;

interface Props {
    contact:any,
    onVerified:()=>void
    submitButton?:string
}

const ContactVerification = ({contact, onVerified, submitButton='Continue'}:Props) => {
    const {viewer} = useViewer();
    const [verifyContact] = useMutation<VerifyContact, VerifyContactVariables>(VERIFY_CONTACT);
    const [error, setError] = useState<string|null>(null);
    const sendVerificationCode = useSendVerificationCode(contact);

    const verified = !!((contact && contact.verifiedAt) || (viewer && viewer.onboardedBy));

    const [pin, setPin] = useState<Array<string>>([]);

    const valid = pin.join('').length === 5;

    const onSubmit = (e:FormEvent) => {
        e.preventDefault();

        if (verified)
            return onVerified();

        if (!valid)
            return setError('Incomplete PIN');

        const code = pin.join('');

        if (contact) {
            verifyContact({
                variables: {
                    input: {
                        contactId: contact.id,
                        code
                    },
                    contactId: contact.id
                }
            }).then(result => {
                const {verified, message} = (result && result.data && result.data.verifyContact && result.data.verifyContact.verifyContactResult)||{verified:false,message:'error'};

                if (verified)
                    onVerified();
                else {
                    setError(message);
                    setPin([]);
                }
            });
        }
    };

    const updatePin = (i:number) => {
        return (c:string) => {
            if (verified)
                return;

            const newPin = pin.slice();

            newPin[i] = c;

            setPin(newPin);

            if (error)
                setError(null);
        }
    };

    const onPaste = (e:any) => {
        if (verified)
            return;

        const pin = e &&
            e.clipboardData &&
            e.clipboardData.getData &&
            e.clipboardData.getData('Text')
                .replace(/\s+/g, '')
                .substring(0, 5);

        if (pin && pin.length === 5)
            setPin(pin.split(''));
    };

    const resendCode = () => {
        sendVerificationCode(true);
    }

    const showResendButton = !verified && contact && contact.verificationCodeSent && (60 * 1000 < Date.now() - new Date(contact.verificationCodeSent).getTime());

    return (
        <ContactVerificationWrapper>
            <PinEntry onSubmit={onSubmit}>
                {[0,1,2,3,4].map(i => (
                    <Digit
                        key={i}
                        value={verified ? '*' : (i < pin.length ? pin[i] : '')}
                        onChange={updatePin(i)}
                        onPaste={onPaste}
                        disabled={verified}
                        onContinue={onSubmit}
                    />
                ))}
            </PinEntry>

            {error && <Error>{error}</Error>}

            <Buttons>
                {showResendButton && <Button variation="white" onClick={resendCode}>Resend Code</Button>}
                <Button variation="light-blue" onClick={onSubmit} disabled={!(valid || verified)}>{submitButton}</Button>
            </Buttons>
        </ContactVerificationWrapper>
    )
};

export default ContactVerification;