import React from 'react';

import Step from './Step';

import {useConsumerViewer} from "../../../../lib/use-viewer";
import {PageProps} from "../../../../lib/create-track";
import Content from "./Content";
import TopMessage from "./TopMessage";
import Name from "../../../../components/Name";
import ContactVerification from "../../../../components/ContactVerification";

const VerifyPhone = (({page:{next}}:PageProps) => {
    const {viewer} = useConsumerViewer();
    const contact = viewer && viewer.contacts.nodes.find(contact => !!(contact && contact.type === 'phone'));
    const phone = contact && contact.value.number;

    return (
        <Step heading="Verify Phone Number">
            <Content>
                <TopMessage>
                    <p>Congratulations <Name>{viewer && viewer.firstName}!</Name></p>

                    <p>Welcome to RBN.</p>

                    <p>
                        You've taken the first step to earning rewards for your next home purchase. We'll need to verify
                        your phone number and then we'll ask some additional questions to match you with an
                        RBN participating agent.
                    </p>
                    <p>
                        To continue, please check <strong>{phone}</strong> for the <strong>RBN - Verification Required</strong> text
                        message containing the PIN and enter it below.
                    </p>
                </TopMessage>

                <ContactVerification contact={contact} onVerified={() => next({valid: true})}/>
            </Content>
        </Step>
    )
});

export default VerifyPhone;
