import React, {useCallback, useEffect, useRef, useState} from 'react';

import {apolloClient, geoLookup} from '../../config';
import AutoComplete, {Option} from "./AutoComplete";

import AUTOCOMPLETE_LOCATION from '../../gql/AutocompleteLocation';
import {AutocompleteLocation, AutocompleteLocationVariables} from '../../gql/types/AutocompleteLocation';
import {useLazyQuery} from "@apollo/client";
import {useLocation, useParams} from "react-router";
import {StateProps} from "../../pages/Wizard/Wizard";

export interface LocationOption extends Option {
}

interface ComponentProps {
    label?: string,
    grow?: boolean,
    placeholder?: string,
    onSelect: (location:LocationOption|null) => boolean|void,
    defaultValue?: LocationOption,
    selectOnBlur?: boolean,
    [key:string]: any
}

const AutoCompleteLocation = ({defaultValue, ...props}:ComponentProps) => {
    const [input, setInput] = useState(defaultValue ? `${defaultValue.label}` : '');
    const [zipCode, setZipCode] = useState((defaultValue && defaultValue.id) || '');
    const callbackRef = useRef<any>(null);
    const [findLocations, {data, loading}] = useLazyQuery<AutocompleteLocation, AutocompleteLocationVariables>(AUTOCOMPLETE_LOCATION);
    const {state} = useLocation<StateProps>();
    const [value, setValue] = useState<LocationOption|undefined>((state && state.location) || undefined);

    const params:any = useParams()

    useEffect(() => {
        if (value)
            return;

        geoLookup()
            .then(({city, state, zip}:any) => {
                const location = params && (params.city || params.location);

                if (!location)
                    setValue({id: zip, label: `${city}, ${state}`});
                else {
                    apolloClient.query<AutocompleteLocation, AutocompleteLocationVariables>({
                            query: AUTOCOMPLETE_LOCATION,
                            variables: {
                                search: location,
                                zipCode: zip || '85260'
                            }
                        })
                        .then(result => {
                            const list = result && result.data && result.data.autocompleteLocation;
                            if (list && 0 < list.length) {
                                const {zip, city, state} = list[0];
                                setValue({id: zip, label: `${city}, ${state}`})
                            }
                        });
                }
                setZipCode(zip);
            });
    }, [params, value]);

    useEffect(() => {
        if (loading)
            return;

        const locations = (data && data.autocompleteLocation) || [];

        if (callbackRef.current) {
            callbackRef.current(locations
                .slice(0, 5)
                .map(({zip, city, state}: any) => ({id: zip, label: `${city}, ${state}`}))
            );
        }
    }, [data, loading]);

    useEffect(() => {
        if (!input)
            return;

        findLocations({
            variables: {
                search: input.trim(),
                zipCode: zipCode || '85260'
            }
        });
    }, [findLocations, input, zipCode]);

    const onInputChange = useCallback((input:string, callback:(options:LocationOption[]) => void) => {
        setInput(input);

        if (!input)
            return Promise.resolve([]);

        callbackRef.current = callback;
    }, [setInput, callbackRef]);

    return (
        <AutoComplete<LocationOption> onInputChange={onInputChange} value={value} {...props}/>
    )
};

export default AutoCompleteLocation;