import gql from 'graphql-tag';

export const fragments = {
    provider: gql`
        fragment ProviderData on Provider {
            nodeId
            id
            name
            title
            gender
            slug
            phone
            email
            image {
                url
            }
            info
            transactionHistory
            setupComplete
            approvalRequested
            approved
            public
        }
    `
};

export const ProviderQuery = gql`
    query Provider($nodeId: ID!) {
        provider(nodeId: $nodeId) {
            ...ProviderData
        }
    }
    
    ${fragments.provider}
`;

export const ProviderByIdQuery = gql`
    query ProviderById($id: UUID!) {
        providerById(id: $id) {
            ...ProviderData
        }
    }
    
    ${fragments.provider}
`;

export const ProviderBySlugQuery = gql`
    query ProviderBySlug($slug: Slug!) {
        providerBySlug(slug: $slug) {
            ...ProviderData
        }
    }
    
    ${fragments.provider}
`;

export const ProviderByEmailQuery = gql`
    query ProviderByEmail($email: String!) {
        getProviderByEmail(email: $email) {
            account {
                firstName
                lastName
            }
            ...ProviderData
        }
    }
    
    ${fragments.provider}
`;

export const UpdateProviderMutation = gql`
    mutation UpdateProvider($input: UpdateProviderInput!) {
        updateProvider(input: $input) {
            provider {
                nodeId
                id
                name
                title
                slug
                phone
                email
                image {
                    url
                }
                info
                transactionHistory
                setupComplete
                approvalRequested
                approved
                public
                areaUpdated
            }
        }
    }
`;